import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const counterpartyDocumentTypesAp = createApi({
  reducerPath: 'counterpartyDocumentTypesAp',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/counterparty-document-types`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    counterpartyDocumentTypes: builder.query<ReferenceBook[], void>({
      query: () => ({
        url: `/`,
      }),
    }),
  }),
});

export const { useCounterpartyDocumentTypesQuery } =
  counterpartyDocumentTypesAp;
