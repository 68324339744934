import { ReactNode } from 'react';

import InfoMessage from '@components/ui/InfoMessage';

export const EmptyMessage = ({
  title,
  subtitle,
  error,
  button,
}: {
  title?: string;
  subtitle?: string;
  error?: boolean;
  button?: ReactNode;
}) => (
  <InfoMessage
    title={title}
    subtitle={subtitle}
    sx={{ flex: 1, height: '100%' }}
    error={error}
    button={button}
  />
);

export const DefaultEmptyMessage = (
  <EmptyMessage
    title='В даному розділі немає записів'
    subtitle='Перейдіть до створення'
  />
);
