import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { capitalize } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import store from '@/app/store';
import {
  StepState,
  resetStepsDataAction,
  selectActsAndDocumentsState,
  setInitialStepsAction,
  setStepDataAction,
  setStepIsDirtyAction,
  setStepIsValidAction,
} from '@/features/actsAndDocuments/actsAndDocumentsSlice';
import useConfirmDialog from '@/hooks/useConfirmPrompt';

import {
  StepNavigationDirection,
  useLastPathSegment,
  useStepNavigation,
} from '@components/ActsAndDocumentsForm/context/hooks';
import {
  ActsAndDocumentsFormContextType,
  ActsAndDocumentsFormStepViews,
  ActsAndDocumentsFormSteps,
  FileLikeObject,
  StepKey,
} from '@components/ActsAndDocumentsForm/context/types';

export const ActsAndDocumentsFormContext =
  createContext<ActsAndDocumentsFormContextType>(null);

export const useActsAndDocumentsFormContext = <T extends object = object>() => {
  const context = useContext(
    ActsAndDocumentsFormContext,
  ) as ActsAndDocumentsFormContextType<T>;
  if (!context) {
    throw new Error(
      'useActsAndDocumentsFormContext must be used within a ActsAndDocumentsFormContext',
    );
  }

  return context;
};

export type ActFormContextInitialDataSubmitMethod = (args: {
  stepsState: StepState<string>;
  isEditMode?: boolean;
  selectedFiles?: File[] | FileLikeObject[];
  documentsToDelete?: FileLikeObject[];
}) => Promise<boolean>;

export type ActFormContextInitialData<T extends object = object> = {
  act?: T;
  confirm: ReturnType<typeof useConfirmDialog>['confirm'];
  firstStep: StepKey<ActsAndDocumentsFormSteps>;
  lastStep: StepKey<ActsAndDocumentsFormSteps>;
  steps: ActsAndDocumentsFormSteps;
  basePath: string;
  submitMethod: ActFormContextInitialDataSubmitMethod;
  stepViews: ActsAndDocumentsFormStepViews;
};

type ActsAndDocumentsFormContextProviderProps<T extends object = object> = {
  isEditMode?: boolean;
  init: ActFormContextInitialData<T>;
} & PropsWithChildren;

export const ActsAndDocumentsFormContextProvider = <T extends object = object>({
  children,
  init,
  isEditMode,
}: ActsAndDocumentsFormContextProviderProps<T>) => {
  const {
    steps,
    firstStep,
    lastStep,
    basePath,
    confirm,
    submitMethod,
    stepViews,
    act,
  } = init;

  const navigate = useNavigate();

  const currentRoute = useLastPathSegment();
  const [currentStep, setCurrentStep] = useState<
    StepKey<ActsAndDocumentsFormSteps>
  >(() => {
    const nextStep = capitalize(currentRoute);

    if (nextStep in steps) {
      return steps[nextStep];
    }

    return firstStep;
  });

  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initSteps = Object.keys(steps);
    dispatch(setInitialStepsAction({ initSteps }));
    setIsInitialized(true);

    return () => {
      dispatch(resetStepsDataAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLastStep = useMemo(
    () => currentStep === lastStep,
    [currentStep, lastStep],
  );

  const isFirstStep = useMemo(
    () => currentStep === firstStep,
    [currentStep, firstStep],
  );

  const [currentStepValidation, setCurrentStepValidation] = useState<
    Partial<Record<StepKey<ActsAndDocumentsFormSteps>, () => boolean>>
  >({});

  const setStepValidation = useCallback(
    (step: StepKey<ActsAndDocumentsFormSteps>, validate: () => boolean) => {
      setCurrentStepValidation(prevState => {
        return { ...prevState, [step]: validate };
      });
    },
    [],
  );

  const setStepIsDirty = useCallback(
    (step: StepKey<ActsAndDocumentsFormSteps>, isDirty: boolean) => {
      dispatch(
        setStepIsDirtyAction({
          step,
          isDirty,
        }),
      );
    },
    [dispatch],
  );

  const setStepIsValid = useCallback(
    (step: StepKey<ActsAndDocumentsFormSteps>, isValid: boolean) => {
      dispatch(setStepIsValidAction({ step, isValid }));
    },
    [dispatch],
  );

  const validateStep = useCallback(
    async (step: StepKey<ActsAndDocumentsFormSteps>) => {
      const validate = currentStepValidation[step];

      if (validate) {
        return Promise.resolve(validate()).then(isValid => {
          setStepIsValid(step, isValid);
          setStepIsDirty(step, isValid);

          return isValid;
        });
      }
    },
    [currentStepValidation, setStepIsDirty, setStepIsValid],
  );

  const setStepData = useCallback(
    (step: StepKey<ActsAndDocumentsFormSteps>, data: any) => {
      dispatch(setStepDataAction({ step, data }));
    },
    [dispatch],
  );

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const [documentsToDelete, setDocumentsToDelete] = useState<FileLikeObject[]>(
    [],
  );

  const reset = useCallback(
    (shouldConfirm = true) => {
      const action = () => {
        dispatch(resetStepsDataAction());
        navigate(basePath);
      };

      if (shouldConfirm) {
        confirm(action);
        return;
      }

      action();
    },
    [basePath, confirm, dispatch, navigate],
  );

  /**
   *   How to select step data:
   *   const isStepValid = useSelector(makeSelectStepIsValid(step));
   *   const isStepDirty = useSelector(makeSelectStepIsDirty(step));
   *   const stepData = useSelector(makeSelectStepData(step));
   */

  const stepsState = useSelector(selectActsAndDocumentsState);

  const submit = useCallback(async () => {
    const stepsState = selectActsAndDocumentsState(store.getState());

    const isSubmitted = await submitMethod({
      stepsState,
      selectedFiles,
      isEditMode,
      documentsToDelete,
    });

    if (isSubmitted) {
      reset(false);
    }
  }, [documentsToDelete, isEditMode, reset, selectedFiles, submitMethod]);

  const stepNavigation = useStepNavigation({
    steps,
    stepViews,
    currentStep,
    setCurrentStep,
  });

  const moveToNextStep = useCallback(() => {
    if (isLastStep) {
      return;
    }

    stepNavigation(StepNavigationDirection.next);
  }, [isLastStep, stepNavigation]);

  const moveToPrevStep = useCallback(() => {
    if (isFirstStep) {
      return;
    }

    stepNavigation(StepNavigationDirection.prev);
  }, [isFirstStep, stepNavigation]);

  return (
    <ActsAndDocumentsFormContext.Provider
      value={{
        currentStep,
        isEditMode,
        isFirstStep,
        isLastStep,
        setStepValidation,
        setStepIsDirty,
        setStepIsValid,
        validateStep,
        setStepData,
        selectedFiles,
        setSelectedFiles,
        reset,
        stepsState,
        submit,
        moveToNextStep,
        moveToPrevStep,
        act,
        steps,
        documentsToDelete,
        setDocumentsToDelete,
      }}
    >
      {isInitialized && children}
    </ActsAndDocumentsFormContext.Provider>
  );
};
