import dayjs from 'dayjs';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_EXTENDED = 'HH:mm:ss';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATE_TIME_FORMAT_EXTENDED = 'DD.MM.YYYY, h:mm:ss';
export const DATE_TIME_FORMAT_EXTENDED_24 = 'DD.MM.YYYY, HH:mm:ss';

export const formatDate = (
  date: string,
  format = DATE_FORMAT,
  inputFormat?: string,
) => {
  return date ? dayjs(date, inputFormat).format(format) : '-';
};
