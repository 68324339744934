import { styled } from '@mui/joy';

const PageContainer = styled('article')(({ theme }) => ({
  height: `calc(100vh - 56px)`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const PageDetailsContainer = styled(PageContainer)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)} 0`,
  overflow: 'hidden',
}));

export const PageListContainer = styled(PageContainer)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingBottom: 0,
  overflow: 'auto',
}));
