import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  OperatingIncome,
  OperatingIncomeCanUpdateConsumptionQueryParams,
  OperatingIncomeFilters,
  OperatingIncomeGetItemQueryParams,
  OperatingIncomeUpdateConsumptionPayload,
  OperatingIncomeUpdatePressurePayload,
} from './types';

export const operatingIncomesApi = createApi({
  reducerPath: 'operatingIncomesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/gas-distribution-station-operational-incomes`,
  }),
  tagTypes: ['OperatingIncomes', 'OperatingIncome'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getOperatingIncomes: builder.query<
      OperatingIncome[],
      OperatingIncomeFilters
    >({
      query: filters => ({
        url: `/gas-thread/query`,
        method: 'POST',
        data: filters,
      }),
      providesTags: ['OperatingIncomes'],
    }),
    getOperatingIncomeById: builder.query<
      OperatingIncome,
      OperatingIncomeGetItemQueryParams
    >({
      query: ({ gasThreadId, date }) => ({
        url: `/gas-thread?gasThreadId=${gasThreadId}&date=${date}`,
      }),
      providesTags: result => [
        {
          type: 'OperatingIncome',
          id: `${result.gasThreadId}-${result.date}`,
        },
      ],
    }),
    updatePressureIn: builder.mutation<
      OperatingIncome,
      OperatingIncomeUpdatePressurePayload
    >({
      query: data => ({
        url: `/gas-thread/pressure-in`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        {
          type: 'OperatingIncome',
          id: `${result.gasThreadId}-${result.date}`,
        },
      ],
    }),
    updatePressureOut: builder.mutation<
      OperatingIncome,
      OperatingIncomeUpdatePressurePayload
    >({
      query: data => ({
        url: `/gas-thread/pressure-out`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        {
          type: 'OperatingIncome',
          id: `${result.gasThreadId}-${result.date}`,
        },
      ],
    }),
    gasThreadValid: builder.query<boolean, OperatingIncomeGetItemQueryParams>({
      query: ({ gasThreadId, date }) => ({
        url: `/gas-thread/valid?gasThreadId=${gasThreadId}&date=${date}`,
      }),
    }),
    canUpdateConsumption: builder.query<
      boolean,
      OperatingIncomeCanUpdateConsumptionQueryParams
    >({
      query: ({ meteringPointId, date }) => ({
        url: `/metering-point/consumption/can-update?meteringPointId=${meteringPointId}&date=${date}`,
      }),
    }),
    updateConsumption: builder.mutation<
      OperatingIncome,
      OperatingIncomeUpdateConsumptionPayload
    >({
      query: data => ({
        url: `/metering-point/consumption`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_, __, params) => [
        {
          type: 'OperatingIncome',
          id: `${params.gasThreadId}-${params.date}`,
        },
      ],
    }),
  }),
});

export const {
  useGetOperatingIncomesQuery,
  useGetOperatingIncomeByIdQuery,
  useUpdatePressureInMutation,
  useUpdatePressureOutMutation,
  useGasThreadValidQuery,
  useCanUpdateConsumptionQuery,
  useUpdateConsumptionMutation,
} = operatingIncomesApi;
