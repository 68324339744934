import { Component, PropsWithChildren } from 'react';

import { ErrorView, handleReloadOnError } from '@/pages/Error';

class ErrorBoundary extends Component<
  PropsWithChildren,
  { hasError: boolean }
> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown) {
    handleReloadOnError(error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorView />;
    }

    return this.props.children || null;
  }
}

export default ErrorBoundary;
