import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

const ActualIncomeReadings = React.lazy(
  () => import('@/pages/ActualIncomeDetails/views/Readings'),
);
const ActualIncomeDailyConsumption = React.lazy(
  () => import('@/pages/ActualIncomeDetails/views/DailyConsumption'),
);
const ActualIncomeOwnNeeds = React.lazy(
  () => import('@/pages/ActualIncomeDetails/views/OwnNeeds'),
);
const ActualIncomeTechnicalCalculations = React.lazy(
  () => import('@/pages/ActualIncomeDetails/views/TechnicalCalculations'),
);
const ActualIncomeAdjustments = React.lazy(
  () => import('@/pages/ActualIncomeDetails/views/Adjustments'),
);

export const ActualIncomeDetailsVies = {
  READINGS: '',
  DAILY_CONSUMPTION: 'daily-consumption',
  OWN_NEEDS: 'own-needs',
  TECHNICAL_CALCULATIONS: 'technical-calculations',
  ADJUSTMENT: 'adjustment',
};

export const actualIncomeDetailsRoutes: RouteObject[] = [
  {
    element: <ActualIncomeReadings />,
    index: true,
    path: ActualIncomeDetailsVies.READINGS,
  },
  {
    element: <ActualIncomeDailyConsumption />,
    path: ActualIncomeDetailsVies.DAILY_CONSUMPTION,
  },
  {
    element: <ActualIncomeOwnNeeds />,
    path: ActualIncomeDetailsVies.OWN_NEEDS,
  },
  {
    element: <ActualIncomeTechnicalCalculations />,
    path: ActualIncomeDetailsVies.TECHNICAL_CALCULATIONS,
  },
  {
    element: <ActualIncomeAdjustments />,
    path: ActualIncomeDetailsVies.ADJUSTMENT,
  },
];
