import { ReactNode } from 'react';

import get from 'lodash-es/get';

import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { IconButton, Tooltip, styled } from '@mui/joy';
import FormControl, { FormControlProps } from '@mui/joy/FormControl';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

import { FormErrorMessage } from '@components/FormFields/FormErrorMessage';
import { FormLabel } from '@components/ui/FormField';
import { TextSkeleton } from '@components/ui/skeletons/TextSkeleton';

const CustomIconButton = styled(IconButton)({
  padding: 0,
  margin: 0,
  minWidth: 'auto',
  minHeight: 'auto',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const CustomTooltip = ({
  tooltip,
  icon = <InfoRoundedIcon sx={{ height: '16px', width: '16px' }} />,
}: {
  tooltip: string;
  icon?: ReactNode;
}) => {
  const tooltipTeax = (
    <Typography sx={{ width: '245px', color: 'neutral.100' }} level='body-sm'>
      {tooltip}
    </Typography>
  );
  return (
    <>
      <Tooltip arrow color='neutral' title={tooltipTeax} placement='bottom'>
        <CustomIconButton>{icon}</CustomIconButton>
      </Tooltip>
    </>
  );
};

export const FormFieldPreview = ({
  show = true,
  label,
  disabled,
  children,
  style,
  loading = false,
  tooltip,
}: Pick<FormFieldProps, 'label' | 'children' | 'disabled' | 'tooltip'> & {
  show?: boolean;
  loading?: boolean;
  style?: FormControlProps['style'];
}) => {
  return show ? (
    <FormControl style={style}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        gap={1}
      >
        {label && (
          <FormLabel preview disabled={disabled}>
            {label}
          </FormLabel>
        )}
        {tooltip && <CustomTooltip tooltip={tooltip} />}
      </Stack>
      <Typography component='div' level='title-sm' fontWeight={600}>
        <TextSkeleton loading={loading} sx={{}}>
          {loading ? '' : children}
        </TextSkeleton>
      </Typography>
    </FormControl>
  ) : null;
};

interface FormFieldProps {
  hidden?: boolean;
  control: any;
  name: string;
  errorName?: string;
  required?: boolean;
  preview?: boolean;
  label?: string | null;
  type?: string;
  placeholder?: string;
  value?: any;
  onChange?: any;
  onBlur?: any;
  component?: any;
  children?: any;
  errorMessage?: string;
  hideErrorMessage?: boolean;
  startDecorator?: ReactNode;
  endDecorator?: ReactNode;
  rightEl?: ReactNode;
  customPreviewRender?: boolean;
  renderPreview?: (props: { name: string; value: any }) => ReactNode;
  disabled?: boolean;
  tooltip?: string;
}

export const FormField = ({
  hidden,
  control,
  preview,
  name,
  errorName,
  label = null,
  required,
  component: Component,
  value,
  onChange,
  onBlur,
  children,
  rightEl,
  errorMessage,
  hideErrorMessage,
  customPreviewRender,
  renderPreview,
  disabled,
  ...props
}: FormFieldProps) => {
  if (hidden || !control) {
    return null;
  }

  const { values, touched, handleChange, handleBlur, errors } = control;

  let isTouched = get(touched, name);

  if (isTouched instanceof Array) {
    isTouched = true;
  } else if (typeof isTouched === 'object') {
    isTouched = Object.values(isTouched).some(v => v);
  }

  const isInvalid =
    !!errorMessage || (!!get(errors, errorName || name) && isTouched);

  const componentProps = {
    ...props,
    name,
    value: value ?? get(values, name),
    onChange: onChange || handleChange,
    onBlur: onBlur || handleBlur,
  };

  if (preview) {
    // TODO remove customPreviewRender prop
    return (
      <FormFieldPreview label={label}>
        {renderPreview?.(componentProps) ||
          (customPreviewRender ? (
            typeof children === 'function' ? (
              children({ ...componentProps, isInvalid })
            ) : Component ? (
              <Component {...componentProps} />
            ) : null
          ) : (
            get(values, name, '-')
          ))}
      </FormFieldPreview>
    );
  }

  if (typeof children === 'function') {
    return (
      <FormControl error={isInvalid}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {label && (
            <FormLabel required={required} disabled={disabled}>
              {label}
            </FormLabel>
          )}
          {rightEl}
        </Stack>
        {children({ ...componentProps, isInvalid })}
        {!hideErrorMessage && isInvalid && (
          <FormErrorMessage>
            {errorMessage || get(errors, errorName || name)}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  } else if (Component) {
    return (
      <FormControl error={isInvalid}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {label && (
            <FormLabel required={required} disabled={disabled}>
              {label}
            </FormLabel>
          )}
          {rightEl}
        </Stack>
        <Component {...componentProps} />
        {!hideErrorMessage && isInvalid && (
          <FormErrorMessage>{get(errors, errorName || name)}</FormErrorMessage>
        )}
      </FormControl>
    );
  } else {
    return null;
  }
};

export default FormField;
