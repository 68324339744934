import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringPointActType } from './types';

export const meteringPointActTypesApi = createApi({
  reducerPath: 'meteringPointActTypesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-point-act-types`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getMeteringPointActTypes: builder.query<MeteringPointActType[], void>({
      query: () => ({
        url: '/query',
        method: 'POST',
        data: {},
      }),
    }),
  }),
});

export const { useGetMeteringPointActTypesQuery } = meteringPointActTypesApi;
