import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringDevicePassportType } from './types';

export const meteringDevicePassportTypeApi = createApi({
  reducerPath: ' meteringDevicePassportTypeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/meter-passport-types`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getMeteringDevicePassportType: builder.query<
      MeteringDevicePassportType[],
      void
    >({
      query: () => ({
        url: '/',
      }),
    }),
  }),
});

export const { useGetMeteringDevicePassportTypeQuery } =
  meteringDevicePassportTypeApi;
