import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringPointTechnicalCalculation } from './types';

export const meteringPointTechnicalCalculationApi = createApi({
  reducerPath: 'meteringPointTechnicalCalculationApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-point-technical-calculations`,
  }),
  tagTypes: [
    'MeteringPointTechnicalCalculations',
    'MeteringPointTechnicalCalculation',
  ],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getMeteringPointTechnicalCalculations: builder.query<
      MeteringPointTechnicalCalculation[],
      any
    >({
      query: data => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: [
        'MeteringPointTechnicalCalculation',
        'MeteringPointTechnicalCalculations',
      ],
    }),
    getMeteringPointTechnicalCalculationById: builder.query<
      MeteringPointTechnicalCalculation,
      string
    >({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: [
        'MeteringPointTechnicalCalculation',
        'MeteringPointTechnicalCalculations',
      ],
    }),
    createMeteringPointTechnicalCalculation: builder.mutation<
      MeteringPointTechnicalCalculation,
      MeteringPointTechnicalCalculation
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringPointTechnicalCalculation',
        { type: 'MeteringPointTechnicalCalculation', id: result.id },
      ],
    }),
    updateMeteringPointTechnicalCalculationById: builder.mutation<
      MeteringPointTechnicalCalculation,
      MeteringPointTechnicalCalculation
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringPointTechnicalCalculation',
        { type: 'MeteringPointTechnicalCalculation', id: result.id },
      ],
    }),
    deleteMeteringPointTechnicalCalculationById: builder.mutation<
      MeteringPointTechnicalCalculation,
      string
    >({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => [
        'MeteringPointTechnicalCalculations',
        { type: 'MeteringPointTechnicalCalculation', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetMeteringPointTechnicalCalculationsQuery,
  useCreateMeteringPointTechnicalCalculationMutation,
  useGetMeteringPointTechnicalCalculationByIdQuery,
  useUpdateMeteringPointTechnicalCalculationByIdMutation,
  useDeleteMeteringPointTechnicalCalculationByIdMutation,
} = meteringPointTechnicalCalculationApi;
