import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash-es';

import { StepDataStorage } from '@components/ActsAndDocumentsForm/context/StepDataStorage';

export type StepState<T extends string> = Record<
  T,
  Partial<{ data: any; isValid: boolean; isDirty: boolean }>
>;

const storage = new StepDataStorage(
  sessionStorage,
  'METERING_POINT_ACT_FORM_DATA',
);

const createInitialState = <T extends string>(steps: T[]): StepState<T> =>
  steps.reduce((acc, step) => {
    acc[step] = { data: null, isValid: true, isDirty: false };
    return acc;
  }, {} as StepState<T>);

export const actsAndDocumentsSlice = createSlice({
  name: 'actsAndDocumentsSlice',
  initialState: createInitialState([]),
  reducers: {
    setStepDataAction: <T extends string>(
      state: StepState<T>,
      action: PayloadAction<{ step: T; data: any }>,
    ) => {
      const { step, data } = action.payload;
      state[step].data = data;

      storage.setStoreItem(state);
    },
    setStepIsValidAction: <T extends string>(
      state: StepState<T>,
      action: PayloadAction<{ step: T; isValid: boolean }>,
    ) => {
      const { step, isValid } = action.payload;
      state[step].isValid = isValid;

      storage.setStoreItem(state);
    },
    setStepIsDirtyAction: <T extends string>(
      state: StepState<T>,
      action: PayloadAction<{ step: T; isDirty: boolean }>,
    ) => {
      const { step, isDirty } = action.payload;
      state[step].isDirty = isDirty;

      storage.setStoreItem(state);
    },
    setInitialStepsAction: <T extends string>(
      state: StepState<T>,
      action: PayloadAction<{ initSteps: T[] }>,
    ) => {
      const savedStepsData = storage.getParsedStepsData();

      if (!isEmpty(savedStepsData)) {
        return savedStepsData;
      }

      const { initSteps } = action.payload;
      state = createInitialState(initSteps);
      storage.setStoreItem(state);
      return state;
    },
    resetStepsDataAction: <T extends string>(state: StepState<T>) => {
      storage.removeStoreItem();
      Object.keys(state).forEach(key => {
        delete state[key as T];
      });
    },
  },
});

export const {
  resetStepsDataAction,
  setInitialStepsAction,
  setStepDataAction,
  setStepIsValidAction,
  setStepIsDirtyAction,
} = actsAndDocumentsSlice.actions;

export const selectActsAndDocumentsState = <T extends string>(state: {
  actsAndDocumentsSlice: StepState<T>;
}) => state.actsAndDocumentsSlice;

export const makeSelectStepData = <T extends string>(step: T) =>
  createSelector([selectActsAndDocumentsState<T>], state => state[step]?.data);

export const makeSelectStepIsValid = <T extends string>(step: T) =>
  createSelector(
    [selectActsAndDocumentsState<T>],
    state => state[step]?.isValid,
  );

export const makeSelectStepIsDirty = <T extends string>(step: T) =>
  createSelector(
    [selectActsAndDocumentsState<T>],
    state => state[step]?.isDirty,
  );
