import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeterLocations } from './types';

export const meterLocationsApi = createApi({
  reducerPath: 'meterLocationsApi',
  tagTypes: ['MeterLocations'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/meter-locations`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getMeterLocations: builder.query<MeterLocations[], void>({
      query: () => ({
        url: '/',
      }),
    }),
    getMeterLocationById: builder.query<MeterLocations, string>({
      query: id => ({
        url: `/${id}`,
      }),
    }),
  }),
});

export const { useGetMeterLocationsQuery, useGetMeterLocationByIdQuery } =
  meterLocationsApi;
