import DialogTitleBasic from '@/components/ui/Dialog/DialogTitleBasic';
import { TechnologicalLossHistoryModel } from '@/features/balanceActs/types';
import { TIME_FORMAT_EXTENDED, formatDate } from '@/forms/utils/formatDate';

import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import SvgIcon from '@mui/joy/SvgIcon';
import Typography from '@mui/joy/Typography';

import FormDialog from '@components/FormDialog';
import { FormFieldPreview } from '@components/FormikField';
import { DialogActions, DialogContent } from '@components/ui/Dialog/styled';
import { InfoBox, InfoBoxRow } from '@components/ui/InfoBox';

import ArrowUpRightIcon from '@assets/arrow-up-right.svg?react';
import ClockIcon from '@assets/clock.svg?react';
import HistoryIcon from '@assets/history.svg?react';

interface LossHistoryModalProps {
  open: boolean;
  onClose: () => void;
  data?: TechnologicalLossHistoryModel[];
}

const DialogIcon = () => (
  <SvgIcon
    inheritViewBox
    component={HistoryIcon}
    sx={{ path: { fill: theme => theme.palette.text.icon } }}
  />
);

const LossHistoryModal = ({
  open,
  onClose,
  data = [],
}: LossHistoryModalProps) => {
  return (
    <FormDialog width='660px' isModalOpen={open} onDialogClose={onClose}>
      <DialogTitleBasic
        icon={<DialogIcon />}
        title='Максимальне ВТВ по Акту (м³/доба)'
        subtitle='Історія розрахунків'
      />
      <DialogContent dividers>
        {data.map(item => (
          <InfoBoxRow key={item.date}>
            <InfoBox>
              <Stack gap={10} flexDirection='row'>
                <FormFieldPreview label='Дата і час'>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography>{formatDate(item.date)}</Typography>
                    <ClockIcon />
                    <Typography sx={{ marginLeft: '2px !important' }}>
                      {formatDate(item.date, TIME_FORMAT_EXTENDED)}
                    </Typography>
                  </Stack>
                </FormFieldPreview>
                <FormFieldPreview label='Максимальне ВТВ по Акту (м³/доба)'>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Stack
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        backgroundColor: 'success.100',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <SvgIcon
                        inheritViewBox
                        component={ArrowUpRightIcon}
                        sx={{
                          path: { fill: theme => theme.palette.success[500] },
                        }}
                      />
                    </Stack>
                    <Typography>
                      {new Intl.NumberFormat('uk-UA').format(
                        item.maxDailyTechnologicalLoss,
                      )}
                    </Typography>
                  </Stack>
                </FormFieldPreview>
              </Stack>
            </InfoBox>
          </InfoBoxRow>
        ))}
      </DialogContent>
      <DialogActions>
        <Button size='sm' onClick={onClose}>
          Закрити
        </Button>
      </DialogActions>
    </FormDialog>
  );
};

export default LossHistoryModal;
