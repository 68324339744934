import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  CommercialMeteringPointOwnership,
  CommercialMeteringPointOwnershipFilters,
  CommercialMeteringPointOwnershipQuery,
  CommercialMeteringPointOwnershipRequestBody,
} from './types';

export const commercialMeteringPointOwnershipsApi = createApi({
  reducerPath: 'commercialMeteringPointOwnershipsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL + '/commercial-metering-point-ownerships',
  }),
  tagTypes: [
    'CommercialMeteringPointOwnership',
    'CommercialMeteringPointOwnerships',
  ],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getCommercialMeteringPointOwnerships: builder.query<
      CommercialMeteringPointOwnershipQuery[],
      CommercialMeteringPointOwnershipFilters | void
    >({
      query: (data = {}) => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: [
        'CommercialMeteringPointOwnership',
        'CommercialMeteringPointOwnerships',
      ],
    }),
    getCommercialMeteringPointOwnershipById: builder.query<
      CommercialMeteringPointOwnership,
      string
    >({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: ['CommercialMeteringPointOwnership'],
    }),
    createCommercialMeteringPointOwnership: builder.mutation<
      CommercialMeteringPointOwnership,
      CommercialMeteringPointOwnershipRequestBody
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'CommercialMeteringPointOwnership',
        { type: 'CommercialMeteringPointOwnership', id: result.id },
      ],
    }),
    updateCommercialMeteringPointOwnership: builder.mutation<
      CommercialMeteringPointOwnership,
      CommercialMeteringPointOwnershipRequestBody
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'CommercialMeteringPointOwnership',
        { type: 'CommercialMeteringPointOwnership', id: result.id },
      ],
    }),
    deleteCommercialMeteringPointOwnership: builder.mutation<
      CommercialMeteringPointOwnership,
      string
    >({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [
        'CommercialMeteringPointOwnership',
        { type: 'CommercialMeteringPointOwnership', id },
      ],
    }),
  }),
});

export const {
  useGetCommercialMeteringPointOwnershipsQuery,
  useGetCommercialMeteringPointOwnershipByIdQuery,
  useCreateCommercialMeteringPointOwnershipMutation,
  useUpdateCommercialMeteringPointOwnershipMutation,
  useDeleteCommercialMeteringPointOwnershipMutation,
} = commercialMeteringPointOwnershipsApi;
