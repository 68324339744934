import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  GasThreadPhysicoChemicalIndicator,
  GasThreadPhysicoChemicalIndicatorFilters,
  GasThreadPhysicoChemicalIndicatorForm,
} from './types';

export const gasThreadPhysicoChemicalIndicatorApi = createApi({
  reducerPath: 'gasThreadPhysicoChemicalIndicatorApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/gas-thread-physico-chemical-indicators`,
  }),
  tagTypes: [
    'GasThreadPhysicoChemicalIndicator',
    'GasThreadPhysicoChemicalIndicator',
  ],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getGasThreadPhysicoChemicalIndicator: builder.query<
      GasThreadPhysicoChemicalIndicator[],
      GasThreadPhysicoChemicalIndicatorFilters | void
    >({
      query: (data = {}) => ({
        url: `/query`,
        method: 'POST',
        data,
      }),
      providesTags: ['GasThreadPhysicoChemicalIndicator'],
    }),
    getGasThreadPhysicoChemicalIndicatorById: builder.query<
      GasThreadPhysicoChemicalIndicator,
      string
    >({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: [
        'GasThreadPhysicoChemicalIndicator',
        'GasThreadPhysicoChemicalIndicator',
      ],
    }),
    createGasThreadPhysicoChemicalIndicator: builder.mutation<
      GasThreadPhysicoChemicalIndicator,
      GasThreadPhysicoChemicalIndicatorForm
    >({
      query: data => ({
        url: ``,
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'GasThreadPhysicoChemicalIndicator',
        { type: 'GasThreadPhysicoChemicalIndicator', id: result.id },
      ],
    }),
    updateGasThreadPhysicoChemicalIndicatorById: builder.mutation<
      GasThreadPhysicoChemicalIndicator,
      GasThreadPhysicoChemicalIndicatorForm
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'GasThreadPhysicoChemicalIndicator',
        { type: 'GasThreadPhysicoChemicalIndicator', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetGasThreadPhysicoChemicalIndicatorQuery,
  useGetGasThreadPhysicoChemicalIndicatorByIdQuery,
  useCreateGasThreadPhysicoChemicalIndicatorMutation,
  useUpdateGasThreadPhysicoChemicalIndicatorByIdMutation,
} = gasThreadPhysicoChemicalIndicatorApi;
