import SortAscIcon from '@/assets/sort-asc.svg?react';
import SortDescIcon from '@/assets/sort-desc.svg?react';

import { SvgPlaceholder } from './GridStyled';

export const ColumnHeaderSortIcon = ({ direction }) => {
  switch (direction) {
    case 'asc':
      return <SortAscIcon />;
    case 'desc':
      return <SortDescIcon />;
    default:
      return <SvgPlaceholder />;
  }
};
