import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringNodeKind } from './types';

export const meteringNodeKindApi = createApi({
  reducerPath: 'meteringNodeKindApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-node-kinds`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getMeteringNodeKind: builder.query<MeteringNodeKind[], any>({
      query: () => ({
        url: '/',
      }),
    }),
  }),
});

export const { useGetMeteringNodeKindQuery } = meteringNodeKindApi;
