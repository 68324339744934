import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { GasDistributionStationCategory } from './type';

export const gasDistributionStationCategoryApi = createApi({
  reducerPath: 'gdStationCategoryApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getGasDistributionStationCategories: builder.query<
      GasDistributionStationCategory[],
      any
    >({
      query: () => ({
        url: '/gas-distribution-station-categories',
      }),
    }),
  }),
});

export const { useGetGasDistributionStationCategoriesQuery } =
  gasDistributionStationCategoryApi;
