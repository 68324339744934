import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { SeasonType } from './types';

export const seasonTypesApi = createApi({
  reducerPath: 'seasonTypesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/season-types`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getSeasonTypes: builder.query<SeasonType[], any>({
      query: () => ({
        url: '/',
      }),
    }),
  }),
});

export const { useGetSeasonTypesQuery } = seasonTypesApi;
