import { ReactNode } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/joy/Typography';
import { ModalProps, SvgIconTypeMap } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@components/ui/Dialog/styled';

interface DialogComponentProps {
  title?: ReactNode;
  titleIcon?: OverridableComponent<SvgIconTypeMap> & { muiName: string };
  children: ReactNode;
  actions?: ReactNode;
  open?: boolean;
  noContent?: boolean;
  onClose?: () => void;
  sx?: DialogProps['sx'];
}
const DialogComponent = ({
  title,
  titleIcon: Icon,
  children,
  actions,
  open = false,
  onClose,
  sx = {},
  noContent,
}: DialogComponentProps) => {
  return (
    <Dialog
      disableEscapeKeyDown
      onClose={onClose}
      open={open as ModalProps['open']}
      sx={{
        ...sx,
        '.MuiPaper-root': {
          ...(sx?.['.MuiPaper-root'] || {}),
          borderRadius: '8px',
        },
      }}
    >
      {title ? (
        <DialogTitle>
          {Icon ? <Icon sx={{ width: 24, height: 24 }} /> : null}
          <Typography level='title-md' fontWeight={600}>
            {title}
          </Typography>
        </DialogTitle>
      ) : null}
      {onClose ? (
        <IconButton aria-label='close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      {noContent ? (
        children
      ) : (
        <DialogContent dividers>{children}</DialogContent>
      )}
      {actions ? <DialogActions>{actions}</DialogActions> : null}
    </Dialog>
  );
};

export default DialogComponent;
