import { useParams } from 'react-router-dom';

import { useGetCommercialMeteringPointByIdQuery } from '@/features/commercialMeteringPoint/commercialMeteringPointsApi';
import { CommercialMeteringPoint } from '@/features/commercialMeteringPoint/types';
import { useGetMeteringPointByIdQuery } from '@/features/meteringPoint/meteringPointApi';
import { MeteringPoint } from '@/features/meteringPoint/types';
import Pages from '@/pages';
import { SoftBoxLink } from '@/pages/RoleDetails/components/SoftBox';
import { extractInformationByPath } from '@/utils';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

import { useGetCounterpartAndContractByActDate } from '@components/ActsAndDocumentsForm';
import { FormFieldPreview } from '@components/FormikField';
import { InfoBox } from '@components/ui/InfoBox';
import { InformationSkeleton } from '@components/ui/skeletons';

interface ActOwnerProps {
  isLoading?: boolean;
  meteringPoint?: MeteringPoint | CommercialMeteringPoint;
  currentActDate?: string;
}

export const ActOwner = ({
  isLoading: isLoadingProp,
  meteringPoint: meteringPointProp,
  currentActDate,
}: ActOwnerProps) => {
  const { meteringPointId, commercialMeteringPointId } = useParams<{
    meteringPointId?: string;
    commercialMeteringPointId?: string;
  }>();

  const isMeteringPointView = meteringPointId !== undefined;
  const isCommercialMeteringPointView = commercialMeteringPointId !== undefined;

  const useGetByIdQuery = isMeteringPointView
    ? useGetMeteringPointByIdQuery
    : useGetCommercialMeteringPointByIdQuery;

  const pointId = meteringPointId || commercialMeteringPointId;

  const { data: meteringPointQuery, isLoading } = useGetByIdQuery(pointId, {
    skip: isLoadingProp !== undefined,
  });

  const meteringPoint = meteringPointProp || meteringPointQuery;

  const getInformationAbout = extractInformationByPath(meteringPoint);

  const meteringPointPath = isMeteringPointView
    ? Pages.METERING_POINTS
    : Pages.COMMERCIAL_METERING_POINTS_PART;

  const meteringPointLabel = isMeteringPointView
    ? 'Точка обліку ГРС'
    : 'Точка обліку';

  const { counterparty, contract } = useGetCounterpartAndContractByActDate({
    currentActDate,
    commercialMeteringPointId,
  });

  return (
    <InformationSkeleton loading={isLoading || isLoadingProp}>
      <InfoBox label='Власник'>
        <FormFieldPreview label={meteringPointLabel}>
          <SoftBoxLink
            to={`../${meteringPointPath}/${pointId}`}
            endDecorator={<ArrowOutwardIcon />}
          >
            {getInformationAbout('name')}
          </SoftBoxLink>
        </FormFieldPreview>
        {isMeteringPointView && (
          <FormFieldPreview label='Нитка ГРС'>
            <SoftBoxLink
              to={`../${Pages.GAS_THREADS}/${getInformationAbout('gasThread.id')}`}
              endDecorator={<ArrowOutwardIcon />}
            >
              {getInformationAbout('gasThread.name')}
            </SoftBoxLink>
          </FormFieldPreview>
        )}
        {isCommercialMeteringPointView && counterparty && (
          <FormFieldPreview label='Контрагент'>
            <SoftBoxLink
              to={`../${Pages.COUNTERPARTIES}/${counterparty.id}`}
              endDecorator={<ArrowOutwardIcon />}
            >
              {counterparty.name}
            </SoftBoxLink>
          </FormFieldPreview>
        )}
        {isCommercialMeteringPointView && contract && (
          <FormFieldPreview label='Договір'>
            <SoftBoxLink to={`../${Pages.CONTRACTS}/${contract.id}`}>
              {contract.name}
            </SoftBoxLink>
          </FormFieldPreview>
        )}
      </InfoBox>
    </InformationSkeleton>
  );
};
