import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash-es';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  Counterpart,
  CounterpartBase,
  CounterpartFilters,
  CounterpartInput,
} from './types';

const normalizeCounterpartPayloadBeforeMutation = (values: Counterpart) => {
  const payload: Partial<CounterpartInput> = omit(values, [
    'classifier',
    'economicSector',
    'legalAddress',
    'actualAddress',
  ]);
  payload.certificateNumber = values.certificateNumber || null;
  payload.mainCounterpartyId = values.hasSubdivisions
    ? null
    : values.mainCounterparty?.id || null;
  payload.classifierId = values.classifier.id;
  payload.economicSectorId = values.economicSector.id;
  payload.isResident = !values.nonResident;
  payload.contactPersons = values.contactPersons.map(person => ({
    id: person?.id,
    firstName: person.firstName,
    middleName: person.middleName,
    lastName: person.lastName,
    positionId: person.position.id,
    email: person?.email,
    phoneNumber: person.phoneNumber,
    isActive: true,
  }));

  payload.legalAddress = {
    settlementId: values.legalAddress.settlement.id,
    streetId: values.legalAddress.street.id,
    streetType: values.legalAddress.street.streetType,
    streetName: values.legalAddress.street.name,
    buildingNumber: values.legalAddress.buildingNumber,
    officeNumber: values.legalAddress.officeNumber,
  };

  if (values.legalAddress.id) {
    payload.legalAddress.id = values.legalAddress.id;
  }

  if (payload.legalAddress.streetId) {
    delete payload.legalAddress.streetType;
    delete payload.legalAddress.streetName;
  } else {
    delete payload.legalAddress.streetId;
  }

  payload.actualAddress = {
    settlementId: values.actualAddress.settlement.id,
    streetId: values.actualAddress.street.id,
    streetType: values.actualAddress.street.streetType,
    streetName: values.actualAddress.street.name,
    buildingNumber: values.actualAddress.buildingNumber,
    officeNumber: values.actualAddress.officeNumber,
  };

  if (values.actualAddress.id) {
    payload.actualAddress.id = values.actualAddress.id;
  }

  if (payload.actualAddress.streetId) {
    delete payload.actualAddress.streetType;
    delete payload.actualAddress.streetName;
  } else {
    delete payload.actualAddress.streetId;
  }

  return payload;
};

export const counterpartiesApi = createApi({
  reducerPath: 'counterpartsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/counterparties`,
  }),
  tagTypes: ['Counterparts', 'Counterpart'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => {
    return {
      getCounterparts: builder.query<
        CounterpartBase[],
        CounterpartFilters | void
      >({
        query: filters => ({
          url: `/query`,
          method: 'POST',
          data: filters || {},
        }),
        providesTags: ['Counterparts'],
      }),
      getCounterpartById: builder.query<Counterpart, string>({
        query: id => ({
          url: `/${id}`,
        }),
        // transformResponse: normalizeSubdivisionResponse,
        providesTags: (_, __, id) => [{ type: 'Counterpart', id }],
      }),
      createCounterpart: builder.mutation<Counterpart, Counterpart>({
        query: data => ({
          url: `/`,
          method: 'POST',
          data: normalizeCounterpartPayloadBeforeMutation(data),
        }),
        invalidatesTags: result => [
          'Counterparts',
          { type: 'Counterpart', id: result.id },
        ],
      }),
      updateCounterpartById: builder.mutation<Counterpart, Counterpart>({
        query: data => ({
          url: `/${data.id}`,
          method: 'PUT',
          data: normalizeCounterpartPayloadBeforeMutation(data),
        }),
        invalidatesTags: result => [
          'Counterparts',
          { type: 'Counterpart', id: result.id },
        ],
      }),
    };
  },
});

export const {
  useGetCounterpartsQuery,
  useCreateCounterpartMutation,
  useGetCounterpartByIdQuery,
  useUpdateCounterpartByIdMutation,
} = counterpartiesApi;
