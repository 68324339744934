import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import { ReferenceBook } from '@/features/organizations/types';
import { STATUS } from '@/pages/MeteringPointDetails/components/MeteringPointStatus';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const meteringPointStatusesApi = createApi({
  reducerPath: 'meteringPointStatusesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-point-statuses`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => {
    return {
      getMeteringPointStatuses: builder.query<
        (ReferenceBook & { color: string })[],
        void
      >({
        query: () => ({
          url: ``,
        }),
        transformResponse(statuses: ReferenceBook[]) {
          return statuses.map(status => ({
            ...status,
            color: STATUS[status.id].color,
          }));
        },
      }),
    };
  },
});

export const { useGetMeteringPointStatusesQuery } = meteringPointStatusesApi;
