import { ReactNode } from 'react';

import Dialog from '@components/ui/Dialog';

interface FormDialogProps {
  children: ReactNode;
  isModalOpen?: boolean;
  onDialogClose?: () => void;
  width?: string;
  height?: string;
  disableScroll?: boolean;
}

const FormDialog = ({
  children,
  onDialogClose,
  isModalOpen = true,
  width,
  height,
  disableScroll = false,
}: FormDialogProps) => {
  return (
    <Dialog
      onClose={onDialogClose}
      open={isModalOpen}
      sx={{
        '.MuiPaper-root': {
          maxWidth: width || '896px',
          width: '100%',
          height,
          borderRadius: '8px',
          overflowY: disableScroll ? 'initial' : 'auto',
        },
      }}
      noContent
    >
      {children}
    </Dialog>
  );
};

export default FormDialog;
