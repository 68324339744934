import { FC } from 'react';

import { MeteringPointStatuses } from '@/features/meteringPointStatuses/types';

import StatusChip from '@components/ui/StatusChip';

type MeteringPointStatusTitle =
  | 'Включена'
  | 'Відключена'
  | 'Ліквідована'
  | 'Не визначено';
type MeteringPointStatus = Record<
  MeteringPointStatuses,
  {
    title: MeteringPointStatusTitle;
    color: string;
  }
>;

export const STATUS: MeteringPointStatus = {
  [MeteringPointStatuses.ENABLED]: {
    title: 'Включена',
    color: 'green',
  },
  [MeteringPointStatuses.DISABLED]: {
    title: 'Відключена',
    color: 'yellow',
  },
  [MeteringPointStatuses.LIQUIDATED]: {
    title: 'Ліквідована',
    color: 'red',
  },
  [MeteringPointStatuses.UNDEFINED]: {
    title: 'Не визначено',
    color: 'grey',
  },
};

type MeteringPointStatusProps = {
  statusId: MeteringPointStatuses | string;
};

export const MeteringPointStatus: FC<MeteringPointStatusProps> = ({
  statusId,
}) => {
  if (!STATUS[statusId]) {
    return null;
  }

  return <StatusChip {...STATUS[statusId]} />;
};
