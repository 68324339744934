import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';
import set from 'lodash-es/set';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import { AssignRoleRequestPayload, Permission } from '@/features/roles/types';
import { isRootRole } from '@/features/roles/utils';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

// TODO rename to permissions api
export const rolesApi = createApi({
  reducerPath: 'rolesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/roles`,
  }),
  tagTypes: ['Roles', 'Role'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => {
    return {
      getRolePermissionsById: builder.query<Record<string, Permission>, string>(
        {
          query: id => ({
            url: `/${id}/permissions`,
          }),
          transformResponse(permissions: Permission[]) {
            return permissions.reduce(
              (acc, permission) => ({ ...acc, [permission.name]: permission }),
              {},
            );
          },
        },
      ),
      getRoleAvailablePermissionsById: builder.query<Permission[], string>({
        query: id => ({
          url: `/${id}/permissions/available`,
        }),
      }),
      getRootRoleAvailablePermissionsById: builder.query<Permission[], string>({
        query: id => ({
          url: `/${id}/permissions/available/by-parent`,
        }),
      }),
      assignRoleById: builder.mutation<void, AssignRoleRequestPayload>({
        query: ({ id, employeeIds }) => ({
          url: `/${id}/assign`,
          method: 'POST',
          data: { organizationId: id, employeeIds },
        }),
      }),
    };
  },
});

export const {
  useGetRootRoleAvailablePermissionsByIdQuery,
  useGetRolePermissionsByIdQuery,
  useGetRoleAvailablePermissionsByIdQuery,
  useAssignRoleByIdMutation,
} = rolesApi;

export const useGetAvailablePermissionsById = (
  roleId?: string,
  options?: Record<string, any>,
) => {
  const useHook = isRootRole(roleId)
    ? useGetRootRoleAvailablePermissionsByIdQuery
    : useGetRoleAvailablePermissionsByIdQuery;

  return useHook(roleId, options);
};

export const useGetRolePermissions = (
  roleId?: string,
): Record<string, boolean> => {
  const { data: permissions } = useGetRolePermissionsByIdQuery(roleId, {
    skip: !roleId,
  });

  const { data: availablePermissions } = useGetAvailablePermissionsById(
    roleId,
    {
      skip: !roleId,
    },
  );

  const rules = {};

  availablePermissions?.forEach(permission => {
    set(rules, permission.name, !!permissions?.[permission.name]);
  });

  return rules;
};

export const useGetRoleAvailablePermissionsMap = (roleId?: string) => {
  const { data: availablePermissions = [] } = useGetAvailablePermissionsById(
    roleId,
    {
      skip: !roleId,
    },
  );

  return availablePermissions.reduce(
    (acc, permission) => ({ ...acc, [permission.name]: permission.id }),
    {},
  );
};
