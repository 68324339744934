import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

import { ActFormView } from '@/pages/MeteringPointActForm/routes';

const MeteringPointActDetailsInfo = React.lazy(
  () => import('@/pages/MeteringPointActDetails/views/Information'),
);

const MeteringPointActDetailsDevices = React.lazy(
  () => import('@/pages/MeteringPointActDetails/views/Devices'),
);

const MeteringPointActDetailsEquipments = React.lazy(
  () => import('@/pages/MeteringPointActDetails/views/Equipments'),
);

const MeteringPointActDetailsSeals = React.lazy(
  () => import('@/pages/MeteringPointActDetails/views/Seals'),
);

const MeteringPointActDetailsPipelineSections = React.lazy(
  () => import('@/pages/MeteringPointActDetails/views/PipelineSections'),
);

export const meteringPointActDetailsRoutes: RouteObject[] = [
  {
    path: ActFormView.Information,
    element: <MeteringPointActDetailsInfo />,
    index: true,
  },
  {
    path: ActFormView.Devices,
    element: <MeteringPointActDetailsDevices />,
  },
  {
    path: ActFormView.Equipments,
    element: <MeteringPointActDetailsEquipments />,
  },
  {
    path: ActFormView.Seals,
    element: <MeteringPointActDetailsSeals />,
  },
  {
    path: ActFormView.PipelineSection,
    element: <MeteringPointActDetailsPipelineSections />,
  },
];
