import { GasPipelineSectionReadSlim } from '@/features/gasPipelineSections/types';
import { getFormattedDate } from '@/utils/getFormattedDate';

import { Typography } from '@mui/joy';
import { GridSortModel } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';

import Table from '@components/Table';

interface PipelineSectionsTableProps {
  sections: GasPipelineSectionReadSlim[];
  isLoading: boolean;
}

const columns: GridColDef<GasPipelineSectionReadSlim>[] = [
  {
    field: 'number',
    headerName: '№',
    width: 70,
    sortable: true,
  },
  {
    field: 'type',
    headerName: 'Тип',
    flex: 1,
    valueGetter: (value: GasPipelineSectionReadSlim['type']) => value.name,
    sortable: true,
  },
  {
    field: 'placing',
    headerName: 'Розташування',
    flex: 1,
    valueGetter: (value: GasPipelineSectionReadSlim['placing']) => value.value,
    sortable: true,
  },
  {
    field: 'pressureCategory',
    headerName: 'Категорія тиску',
    flex: 1,
    valueGetter: (value: GasPipelineSectionReadSlim['pressureCategory']) =>
      value?.value ?? '-',
    sortable: true,
  },
  {
    field: 'length',
    headerName: 'Довжина (м)',
    flex: 1,
    valueGetter: value => value ?? '-',
    sortable: true,
  },
  {
    field: 'initialDate',
    headerName: 'Первинне введення',
    flex: 1,
    valueFormatter: value => getFormattedDate(value),
    sortable: true,
  },
];

const sortModel: GridSortModel = [{ field: 'number', sort: 'asc' }];

export const PipelineSectionsTable = ({
  sections,
  isLoading,
}: PipelineSectionsTableProps) => {
  return (
    <>
      <Typography
        level='title-sm'
        fontWeight={600}
        textColor={'text.secondary'}
      >
        Ділянки газопроводу
      </Typography>

      <Table
        rows={sections}
        columns={columns}
        getRowId={row => row.id}
        sortModel={sortModel}
        loading={isLoading}
      />
    </>
  );
};
