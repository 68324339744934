import { DocumentFileModel } from '@/features/balanceActs/types';
import { DocumentLink } from '@/pages/MeteringPointActDetails/views/Information/components/DocumentLink';

import { List, ListItem, Typography } from '@mui/joy';

interface DocumentsListProps {
  documents: DocumentFileModel[] | null;
}

export const DocumentsList = ({ documents }: DocumentsListProps) => {
  if (!documents?.length) {
    return <Typography level='body-md'>Немає документів</Typography>;
  }

  return (
    <List
      orientation='horizontal'
      wrap
      sx={{
        '--ListItem-paddingLeft': '0',
        '--ListItem-paddingRight': '1.5rem',
      }}
    >
      {documents.map(doc => {
        if (!doc.name || !doc.url) return null;

        return (
          <ListItem key={doc.url}>
            <DocumentLink name={doc.name} url={doc.url} />
          </ListItem>
        );
      })}
    </List>
  );
};
