import { useParams } from 'react-router-dom';

import { useGetBalanceActByIdQuery } from '@/features/balanceActs';
import { getUseQueryHookState } from '@/utils';

export const useFetchBalanceAct = () => {
  const { balanceActId } = useParams<{
    balanceActId: string;
  }>();

  const { data: balanceAct, ...balanceActQuery } = useGetBalanceActByIdQuery(
    balanceActId,
    {
      skip: !balanceActId,
    },
  );

  const isLoading = getUseQueryHookState('isLoading', [balanceActQuery]);
  const isError = getUseQueryHookState('isError', [balanceActQuery]);

  return {
    isLoading,
    isError,
    balanceAct,
  };
};
