import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { OwnDemandType } from './types';

export const ownDemandTypeApi = createApi({
  reducerPath: 'ownDemandTypeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getOwnDemandTypes: builder.query<OwnDemandType[], any>({
      query: () => ({
        url: '/own-demand-types',
      }),
    }),
  }),
});

export const { useGetOwnDemandTypesQuery } = ownDemandTypeApi;
