import { ReactNode, useState } from 'react';

import {
  DataGridProps,
  GridColDef,
  GridEventListener,
  GridSortModel,
} from '@mui/x-data-grid';

import {
  COLUMN_HEADER_HEIGHT,
  COLUMN_HEIGHT,
  ColumnHeaderSortIcon,
  DefaultEmptyMessage,
  EmptyMessage as EmptyMessageComponent,
  StyledDataGrid,
} from '../shared/grid';

export const EmptyMessage = EmptyMessageComponent;

export interface TableProps<T> {
  rows: T[];
  autoRowHeight?: boolean;
  columns: GridColDef[];
  onRowClick?: GridEventListener<'rowClick'>;
  loading?: boolean;
  emptyMessage?: ReactNode;
  columnHeaderHeight?: number;
  fixedHeight?: boolean;
  noLineStyles?: boolean;
  getRowId?: (row: T) => string | number;
  footer?: ReactNode;
  sortModel?: DataGridProps['sortModel'];
  disableColumnSorting?: boolean;
}

const Table = <T,>({
  rows,
  columns,
  onRowClick,
  autoRowHeight,
  loading,
  emptyMessage = DefaultEmptyMessage,
  columnHeaderHeight,
  noLineStyles,
  getRowId,
  footer = null,
  sortModel: initialSortModel,
  disableColumnSorting = false,
}: TableProps<T>) => {
  const [currentSortModel, setCurrentSortModel] = useState(initialSortModel);

  const handleSortModelChange = (newModel: GridSortModel) => {
    setCurrentSortModel(newModel.length ? newModel : initialSortModel);
  };

  return (
    <StyledDataGrid
      initialState={{
        sorting: {
          sortModel: initialSortModel,
        },
      }}
      getRowId={getRowId}
      noLineStyles={noLineStyles}
      rows={rows}
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnResize
      columns={columns}
      hideFooter={!footer || !rows?.length}
      hideFooterPagination
      columnHeaderHeight={columnHeaderHeight ?? COLUMN_HEADER_HEIGHT}
      rowHeight={
        !loading && (!rows?.length || autoRowHeight) ? undefined : COLUMN_HEIGHT
      }
      getRowHeight={() =>
        !loading && (!rows?.length || autoRowHeight) ? 'auto' : COLUMN_HEIGHT
      }
      onRowClick={onRowClick}
      slots={{
        columnHeaderSortIcon: ColumnHeaderSortIcon,
        noRowsOverlay: () => emptyMessage,
        footer: () => footer,
      }}
      loading={loading}
      slotProps={{
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
      }}
      autoRowHeight={autoRowHeight}
      sortModel={currentSortModel}
      onSortModelChange={handleSortModelChange}
      footer={!!footer}
      disableColumnSorting={disableColumnSorting}
    />
  );
};

export default Table;
