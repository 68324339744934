import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { ServiceOrganization, ServiceOrganizationRequestBody } from './types';

export const serviceOrganizationsApi = createApi({
  reducerPath: 'serviceOrganizationsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL + '/service-organizations',
  }),
  tagTypes: ['ServiceOrganization', 'ServiceOrganizations'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getServiceOrganizations: builder.query<ServiceOrganization[], void>({
      query: () => ({
        url: '/query',
        method: 'POST',
        data: {},
      }),
      providesTags: ['ServiceOrganization', 'ServiceOrganizations'],
    }),
    getServiceOrganizationById: builder.query<ServiceOrganization, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: ['ServiceOrganization'],
    }),
    createServiceOrganization: builder.mutation<
      ServiceOrganization,
      ServiceOrganizationRequestBody
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'ServiceOrganization',
        { type: 'ServiceOrganization', id: result.id },
      ],
    }),
    updateServiceOrganization: builder.mutation<
      ServiceOrganization,
      ServiceOrganizationRequestBody
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'ServiceOrganization',
        { type: 'ServiceOrganization', id: result.id },
      ],
    }),
  }),
});

export const { useGetServiceOrganizationsQuery } = serviceOrganizationsApi;
