import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

const defaultValuesPageContext = {
  loading: true,
  setLoading: () => {},
};

type PageContextType = {
  loading: boolean;
  setLoading: (v: boolean) => void;
};

export const PageContext = createContext<PageContextType>(
  defaultValuesPageContext,
);

export const usePageContext = () => useContext(PageContext);

export const useWatchPageLoading = (value: boolean) => {
  const context = usePageContext();

  useEffect(() => {
    context.setLoading(value);
  }, [context, value]);

  return context;
};

export const PageProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState(defaultValuesPageContext.loading);

  return (
    <PageContext.Provider
      value={{
        loading,
        setLoading: (v: boolean) => setLoading(v),
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
