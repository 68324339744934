import { useEffect } from 'react';

import { useRouteError } from 'react-router-dom';

import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';

import InfoMessage from '@components/ui/InfoMessage';

const ROUTER_ERRORS = [
  'Failed to fetch dynamically imported module',
  'Importing a module script failed',
];

export const handleReloadOnError = (error: unknown) => {
  const err = error as Error;

  if (err?.message && ROUTER_ERRORS.some(msg => err.message.includes(msg))) {
    window.location.reload();
  }
};

export const ErrorView = () => (
  <Stack sx={{ height: '100vh' }} justifyContent='center'>
    <InfoMessage
      title='Ой, щось пішло не так!'
      subtitle='Спробуйте перезавантажити сторінку або зверніться до адміністратора'
      button={
        <Button size='sm' onClick={() => window.location.reload()}>
          Перезавантажити
        </Button>
      }
    />
  </Stack>
);

const Error = () => {
  const error = useRouteError();

  useEffect(() => {
    handleReloadOnError(error);
  }, [error]);

  return <ErrorView />;
};

export default Error;
