import { InfoBox } from '@/components/ui/InfoBox';
import { InformationSkeleton } from '@/components/ui/skeletons/InformationSkeleton';
import { DocumentFileModel } from '@/features/balanceActs/types';

import { DocumentsList } from './DocumentsList';

interface DocumentsSectionProps {
  documents: DocumentFileModel[] | null;
  isLoading: boolean;
}

export const DocumentsSection = ({
  documents,
  isLoading,
}: DocumentsSectionProps) => {
  return (
    <InformationSkeleton loading={isLoading}>
      <InfoBox label='Додані документи'>
        <DocumentsList documents={documents} />
      </InfoBox>
    </InformationSkeleton>
  );
};
