import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringModemType } from './types';

export const meteringModemTypeApi = createApi({
  reducerPath: 'meteringModemTypeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/modem-types`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getMeteringModemType: builder.query<MeteringModemType[], void>({
      query: () => ({
        url: '/query',
        method: 'POST',
        data: {},
      }),
    }),
  }),
});

export const { useGetMeteringModemTypeQuery } = meteringModemTypeApi;
