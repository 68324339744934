import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash-es';

import {
  CACHE_EXPIRATION_TIME,
  CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
} from '@/features/constants';
import {
  Organization,
  OrganizationBase,
  OrganizationFilters,
  OrganizationInput,
  OrganizationReadModelGDN,
  ReferenceBook,
} from '@/features/organizations/types';
import { extendWithRoles } from '@/features/roles/utils';
import normalizePayload from '@/forms/utils/normalizePayload';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const normalizeOrganizationPayloadBeforeMutation = (
  values: Organization,
) => {
  const payload: Partial<OrganizationInput> = omit(values, [
    'type',
    'classifier',
    'economicSector',
    'representative',
    'address',
  ]);
  payload.typeId = values.type.id;
  payload.classifierId = values.classifier?.id || null;
  payload.economicSectorId = values.economicSector?.id || null;

  payload.representative = {
    departmentId: values.representative.departmentId,
    positionId: values.representative.positionId,
    firstName: values.representative.firstName,
    middleName: values.representative.middleName,
    lastName: values.representative.lastName,
    email: values.representative.email,
    phoneNumber: values.representative.phoneNumber,
    startDate: values.representative.startDate,
    endDate: values.representative.endDate,
  };

  payload.address = {
    settlementId: values.address.settlement.id,
    streetId: values.address.street.id,
    streetType: values.address.street.streetType,
    streetName: values.address.street.name,
    buildingNumber: values.address.buildingNumber,
    officeNumber: values.address.officeNumber,
  };

  if (values.address.id) {
    payload.address.id = values.address.id;
  }

  if (payload.address.streetId) {
    delete payload.address.streetName;
  } else {
    delete payload.address.streetId;
  }

  return normalizePayload(payload);
};
const normalizeOrganizationResponse = (data: Organization) => {
  if (data?.certification.code === null) {
    data.certification.code = '';
  }
  if (data?.certification.date === null) {
    data.certification.date = '';
  }
  if (data?.address?.buildingNumber === null) {
    data.address.buildingNumber = '';
  }
  if (data?.address?.officeNumber === null) {
    data.address.officeNumber = '';
  }
  if (data?.representative?.email === null) {
    data.representative.email = '';
  }
  if (data?.representative?.phoneNumber === null) {
    data.representative.phoneNumber = '';
  }
  if (data?.details.logo === null) {
    data.details.logo = '';
  }
  return data;
};

interface ReferenceBooksRes {
  organizationTypes: ReferenceBook[];
  organizationClassifiers: ReferenceBook[];
  economicSectors: ReferenceBook[];
  departments: ReferenceBook[];
  employeePositions: ReferenceBook[];
  streetTypes: ReferenceBook[];
}

const api = createApi({
  reducerPath: 'organizationsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/organizations`,
  }),
  tagTypes: ['Organizations', 'Organization'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => {
    return {
      getReferenceBooks: builder.query<ReferenceBooksRes, void>({
        keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
        query: () => ({
          url: '/reference-books',
        }),
      }),
      getOrganizations: builder.query<OrganizationBase[], OrganizationFilters>({
        query: (filters = { name: null }) => ({
          url: `/query`,
          method: 'POST',
          data: filters,
        }),
        providesTags: ['Organizations'],
      }),
      getOrganizationsGdn: builder.query<OrganizationReadModelGDN[], void>({
        query: () => ({
          url: `/query/gdn`,
        }),
        providesTags: ['Organizations'],
      }),
      getOrganizationById: builder.query<Organization, string>({
        query: id => ({
          url: `/${id}`,
        }),
        transformResponse: normalizeOrganizationResponse,
        providesTags: result => [{ type: 'Organization', id: result?.id }],
      }),
      createOrganization: builder.mutation<Organization, Organization>({
        query: data => ({
          url: `/`,
          method: 'POST',
          data: normalizeOrganizationPayloadBeforeMutation(data),
        }),
        invalidatesTags: result => [
          'Organizations',
          { type: 'Organization', id: result.id },
        ],
      }),
      updateOrganizationById: builder.mutation<Organization, Organization>({
        query: data => ({
          url: `/${data.id}`,
          method: 'PUT',
          data: normalizeOrganizationPayloadBeforeMutation(data),
        }),
        invalidatesTags: result => [
          'Organizations',
          { type: 'Organization', id: result.id },
        ],
      }),
      deleteOrganizationById: builder.mutation<Organization, string>({
        query: id => ({
          url: `/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, __, id) => [
          'Organizations',
          { type: 'Organization', id },
        ],
      }),
    };
  },
});

export const organizationsApi = extendWithRoles(api as any);

export const {
  useGetReferenceBooksQuery,
  useGetOrganizationsQuery,
  useGetOrganizationsGdnQuery,
  useGetOrganizationByIdQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationByIdMutation,
} = api;
