import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { GasPipelineSectionsLocationSettingsReferenceBook } from './types';

export const gasPipelineSectionLocationsApi = createApi({
  reducerPath: 'gasPipelineSectionLocationsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/gas-pipeline-sections-locations`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getGasPipelineSectionLocations: builder.query<
      GasPipelineSectionsLocationSettingsReferenceBook[],
      void
    >({
      query: () => ({
        url: '/',
      }),
    }),
  }),
});

export const { useGetGasPipelineSectionLocationsQuery } =
  gasPipelineSectionLocationsApi;
