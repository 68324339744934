import { styled } from '@mui/joy';
import Stack from '@mui/joy/Stack';

export const Column = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.neutral[300]}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

export const Block = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.25),
}));
