import { useEffect, useState } from 'react';

import { FormikConfig, FormikValues, useFormik } from 'formik';
import { isEqual, pick } from 'lodash-es';

import { DialogType } from '@components/ui/Dialog/types';

interface UseFormModeProps {
  type: DialogType;
}

export const useFormMode = ({ type }: UseFormModeProps) => {
  const [formType, setFormType] = useState(type);

  useEffect(() => {
    setFormType(type);
  }, [type]);

  const isCreateMode = formType === DialogType.Create;
  const isPreviewMode = formType === DialogType.Preview;
  const isEditMode = formType === DialogType.Edit;

  return {
    formType,
    setFormType,
    isCreateMode,
    isPreviewMode,
    isEditMode,
  };
};

interface UseFormProps<T> extends Partial<FormikConfig<T>>, UseFormModeProps {
  defaultValues: T;
}

export const useForm = <T extends FormikValues>({
  type,
  defaultValues,
  initialValues,
  initialErrors,
  validationSchema,
  onSubmit,
}: UseFormProps<T>) => {
  const formMode = useFormMode({ type });
  const [isDirty, setIsDirty] = useState(false);
  const formik = useFormik<T>({
    enableReinitialize: true,
    initialValues,
    initialErrors,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit,
  });
  const { values } = formik;

  useEffect(() => {
    const pickedValues = pick(values, Object.keys(defaultValues));
    const pickedInitialValues = formMode.isCreateMode
      ? defaultValues
      : pick(initialValues, Object.keys(defaultValues));

    const isDirty = Object.keys(pickedValues).some(
      key => !isEqual(pickedValues[key], pickedInitialValues[key]),
    );
    setIsDirty(isDirty);
  }, [defaultValues, formMode.isCreateMode, initialValues, values]);

  return {
    isDirty,
    formik,
    formMode,
  };
};
