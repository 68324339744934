export type MeteringPointStatus = {
  id: string;
  value: string;
};

export enum MeteringPointStatuses {
  ENABLED = 'e80bb1b3-698c-4457-962c-df5dd7a2fb95',
  DISABLED = 'c738d50f-8089-4470-94f0-c83f4574ffc2',
  LIQUIDATED = 'f80f15a3-57c6-453e-93b4-45896d3a7e7b',
  UNDEFINED = 'ad296d15-de10-4916-b53e-16deb9d2d621',
}
