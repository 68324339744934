import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringNodeLocationType } from './types';

export const meteringNodeLocationTypeApi = createApi({
  reducerPath: 'meteringNodeLocationTypeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-node-locations`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getMeteringNodeLocationType: builder.query<MeteringNodeLocationType[], any>(
      {
        query: () => ({
          url: '/',
        }),
      },
    ),
  }),
});

export const { useGetMeteringNodeLocationTypeQuery } =
  meteringNodeLocationTypeApi;
