import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

export const useLastPathSegment = () => {
  const location = useLocation();

  return useMemo(
    () => location.pathname.split('/').filter(Boolean).pop(),
    [location],
  );
};
