import { useMemo } from 'react';

import { isNil } from 'lodash-es';
import { useParams } from 'react-router-dom';

import { useGetCommercialMeteringPointByIdQuery } from '@/features/commercialMeteringPoint/commercialMeteringPointsApi';
import { useGetMeteringPointByIdQuery } from '@/features/meteringPoint/meteringPointApi';
import Pages from '@/pages';
import { getUseQueryHookState } from '@/utils';

export const useBreadcrumbsNavConfig = () => {
  const { meteringPointId, commercialMeteringPointId, contextOrganizationId } =
    useParams<{
      meteringPointId?: string;
      commercialMeteringPointId?: string;
      contextOrganizationId?: string;
    }>();

  const isCommercialMeteringPointView = !isNil(commercialMeteringPointId);

  const { data: meteringPoint, ...meteringPointQuery } =
    useGetMeteringPointByIdQuery(meteringPointId, { skip: !meteringPointId });

  const { data: commercialMeteringPoint, ...commercialMeteringPointQuery } =
    useGetCommercialMeteringPointByIdQuery(commercialMeteringPointId, {
      skip: !commercialMeteringPointId,
    });

  const isLoading = getUseQueryHookState('isLoading', [
    meteringPointQuery,
    commercialMeteringPointQuery,
  ]);

  const basePath = isCommercialMeteringPointView
    ? `/${contextOrganizationId}/${Pages.COMMERCIAL_METERING_POINTS_PART}`
    : `/${contextOrganizationId}/${Pages.METERING_POINTS}`;

  const label = isCommercialMeteringPointView
    ? 'Комерційна точка обліку'
    : 'Точки обліку ГРС';

  const meteringPointLabel = isCommercialMeteringPointView
    ? commercialMeteringPoint?.name
    : meteringPoint?.name;

  const navConfig = useMemo(
    () => [
      {
        label,
        path: basePath,
      },
      {
        label: meteringPointLabel,
        path: basePath + `/${meteringPointId || commercialMeteringPointId}`,
        loading: isLoading,
      },
    ],
    [
      basePath,
      isLoading,
      meteringPointLabel,
      meteringPointId,
      label,
      commercialMeteringPointId,
    ],
  );

  return {
    navConfig,
  };
};
