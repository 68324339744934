import { SkeletonProps } from '@mui/joy';

import { TextSkeleton } from '@components/ui/skeletons/TextSkeleton';

import { Block, Column } from './styled';

export const TextColumnSkeleton = ({ sx }: SkeletonProps) => {
  return (
    <Column sx={sx}>
      <TextSkeleton width='102px' />
      <Block>
        <TextSkeleton width='102px' />
        <TextSkeleton width='187px' />
      </Block>
      <Block>
        <TextSkeleton width='144px' />
        <TextSkeleton width='187px' />
      </Block>
      <TextSkeleton width='42px' />
      <Block>
        <TextSkeleton width='182px' />
        <TextSkeleton width='119px' />
      </Block>
      <Block>
        <TextSkeleton width='226px' />
        <TextSkeleton width='332px' />
      </Block>
      <TextSkeleton width='102px' />
      <Block>
        <TextSkeleton width='102px' />
        <TextSkeleton width='187px' />
      </Block>
      <Block>
        <TextSkeleton width='54px' />
        <TextSkeleton width='312px' />
      </Block>
      <TextSkeleton width='180px' />
      <Block>
        <TextSkeleton width='221px' />
        <TextSkeleton width='83px' />
      </Block>
      <Block>
        <TextSkeleton width='118px' />
        <TextSkeleton width='53px' />
      </Block>
      <Block>
        <TextSkeleton width='169px' />
        <TextSkeleton width='258px' />
      </Block>
    </Column>
  );
};
