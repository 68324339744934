import { Divider, Skeleton, styled } from '@mui/joy';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import MuiSvgIcon from '@mui/material/SvgIcon';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const SvgIcon = styled(MuiSvgIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'block',
  '& > path': {
    fill: theme.palette.text.primary,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.fontSize.xl,
  fontWeight: 600,
  color: theme.palette.neutral[800],
}));

export const SubtitleDivider = styled(Divider)(({ theme }) => ({
  background: theme.palette.neutral[600],
}));

export const StyledSkeleton = styled(Skeleton)<{ mw?: string }>`
  max-width: ${({ mw }) => mw};
`;

export const StyledSubtitle = styled(Stack)(
  ({ theme }) => `
  flex-direction: row;
  align-items: center;
  margin-left: ${theme.spacing(5)};
  gap: ${theme.spacing(1)};
  
  & > .MuiTypography-root:first-of-type {
    color: ${theme.palette.neutral[800]}
  }
`,
);

export const StyledPageTitle = styled(Box)<{ sticky?: boolean }>(
  ({ sticky }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: sticky ? 'center' : 'flex-start',
    flexDirection: sticky ? 'row' : 'column',
  }),
);
