import { ReactNode, Ref, forwardRef } from 'react';

import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { StackProps } from '@mui/joy/Stack';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

interface InfoMessageProps {
  icon?: ReactNode;
  title: string;
  subtitle?: string;
  sx?: StackProps['sx'];
  error?: boolean;
  button?: ReactNode;
}

const InfoMessage = forwardRef(
  (
    {
      error,
      button,
      icon = <InfoRoundedIcon color={error ? 'error' : 'primary'} />,
      title,
      subtitle,
      sx,
    }: InfoMessageProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <Stack
        ref={ref}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',

          py: 2,
          gap: 1,
          ...sx,
        }}
      >
        {icon}
        <Typography level='title-md' fontWeight={600}>
          {title}
        </Typography>
        {subtitle ? (
          <Typography
            level='body-sm'
            sx={{ color: 'neutral.600', maxWidth: '400px' }}
          >
            {subtitle}
          </Typography>
        ) : null}
        {button}
      </Stack>
    );
  },
);

export default InfoMessage;
