import {
  ForwardedRef,
  ReactNode,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';

import { useFormik } from 'formik';
import { Schema } from 'yup';

import useConfirmDialog from '@/hooks/useConfirmPrompt';
import { useFormMode } from '@/hooks/useForm';

import Divider from '@mui/joy/Divider';
import Stack from '@mui/joy/Stack';

import { FormFooter } from '@components/FormFooter';
import { DialogContent } from '@components/ui/Dialog/styled';
import { DialogType } from '@components/ui/Dialog/types';

export type FormWithRefHandle = {
  handleClose: () => void;
};

type FormWithRefProps<T, P> = {
  type?: DialogType;
  onClose?: () => void;
  initialValues?: T;
  handleSubmit?: (formData: T) => void;
  children: (formik: ReturnType<typeof useFormik>) => ReactNode;
  validationSchema?: Schema | ((params: P) => Schema);
  schemaParams?: P;
  hideCancelButton?: boolean;
  submitButtonTitle?: string;
};

function FormWithRef<T extends Record<string, any>, P>(
  {
    type = DialogType.Create,
    initialValues = {} as T,
    onClose,
    handleSubmit,
    children,
    validationSchema,
    schemaParams,
    hideCancelButton,
    submitButtonTitle,
  }: FormWithRefProps<T, P>,
  ref: ForwardedRef<FormWithRefHandle>,
) {
  const { confirm, ConfirmDialog } = useConfirmDialog();
  const { setFormType, isCreateMode, isPreviewMode } = useFormMode({
    type,
  });

  const isLoading = false;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema:
      typeof validationSchema === 'function'
        ? validationSchema(schemaParams)
        : validationSchema,
    onSubmit: (formData: T) => {
      handleSubmit(formData);
      onClose();
    },
  });

  const { submitForm, resetForm, dirty: isDirty } = formik;

  const handleClose = useCallback(async () => {
    const closeForm = () => {
      onClose();
      resetForm();
    };
    if (isDirty) {
      confirm(closeForm);
    } else {
      closeForm();
    }
  }, [confirm, isDirty, onClose, resetForm]);

  useImperativeHandle(
    ref,
    () => ({
      handleClose,
    }),
    [handleClose],
  );

  return (
    <>
      <DialogContent dividers>
        <Stack divider={<Divider />} gap={2}>
          {children(formik)}
        </Stack>
      </DialogContent>
      <FormFooter
        isCreateMode={isCreateMode}
        isPreviewMode={isPreviewMode}
        isLoading={isLoading}
        setFormType={setFormType}
        handleCancel={!hideCancelButton && handleClose}
        submitForm={submitForm}
        textSubmit={submitButtonTitle}
      />
      <ConfirmDialog />
    </>
  );
}

export const ForwardedRefForm = forwardRef(FormWithRef);
