import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { GasThread, GasThreadFilters, GasThreadRequestBody } from './types';

export const gasThreadApi = createApi({
  reducerPath: 'gasThreadApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL,
  }),
  tagTypes: ['GasThread', 'GasThreads'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getGasThreads: builder.query<GasThread[], GasThreadFilters | void>({
      query: (data = {}) => ({
        url: '/gas-threads/query',
        method: 'POST',
        data,
      }),
      providesTags: ['GasThread', 'GasThreads'],
    }),
    getGasThreadById: builder.query<GasThread, string>({
      query: id => ({
        url: `/gas-threads/${id}`,
      }),
      providesTags: ['GasThread'],
    }),
    createGasThread: builder.mutation<GasThread, GasThreadRequestBody>({
      query: data => ({
        url: '/gas-threads',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'GasThread',
        { type: 'GasThread', id: result.id },
      ],
    }),
    updateGasThread: builder.mutation<GasThread, GasThreadRequestBody>({
      query: data => ({
        url: `/gas-threads/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'GasThread',
        { type: 'GasThread', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetGasThreadsQuery,
  useLazyGetGasThreadByIdQuery,
  useGetGasThreadByIdQuery,
  useCreateGasThreadMutation,
  useUpdateGasThreadMutation,
} = gasThreadApi;
