import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  OperatingGasRoute,
  OperatingGasRouteFilter,
  OperatingGasRouteRequestPayload,
} from './types';

export const operatingGasRoutesApi = createApi({
  reducerPath: 'operatingGasRoutesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/operating-gas-routes`,
  }),
  tagTypes: ['OperatingGasRoutes', 'OperatingGasRoute'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getOperatingGasRoutes: builder.query<
      OperatingGasRoute[],
      OperatingGasRouteFilter | void
    >({
      query: filters => ({
        url: `/query`,
        method: 'POST',
        data: filters || {},
      }),
      providesTags: ['OperatingGasRoutes'],
    }),
    getOperatingGasRouteById: builder.query<OperatingGasRoute, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: (_, __, id) => [{ type: 'OperatingGasRoute', id }],
    }),
    createOperatingGasRoute: builder.mutation<
      OperatingGasRoute,
      OperatingGasRouteRequestPayload
    >({
      query: data => ({
        url: ``,
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'OperatingGasRoutes',
        { type: 'OperatingGasRoute', id: result.id },
      ],
    }),
    updateOperatingGasRoute: builder.mutation<
      OperatingGasRoute,
      OperatingGasRouteRequestPayload
    >({
      query: ({ id, ...data }) => ({
        url: `/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'OperatingGasRoutes',
        { type: 'OperatingGasRoute', id: result.id },
      ],
    }),
    deleteOperatingGasRoute: builder.mutation<OperatingGasRoute, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => [
        'OperatingGasRoutes',
        { type: 'OperatingGasRoute', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetOperatingGasRoutesQuery,
  useGetOperatingGasRouteByIdQuery,
  useCreateOperatingGasRouteMutation,
  useUpdateOperatingGasRouteMutation,
  useDeleteOperatingGasRouteMutation,
} = operatingGasRoutesApi;
