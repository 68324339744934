import { NavLink, NavLinkProps } from 'react-router-dom';

import cutTitle from '@/utils/cutTitle';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Skeleton, styled } from '@mui/joy';
import MuiBreadcrumbs from '@mui/joy/Breadcrumbs';
import MuiLink, { LinkProps } from '@mui/joy/Link';

const BreadcrumbsContainer = styled(MuiBreadcrumbs)(({ theme }) => ({
  gap: theme.spacing(1.25),
  padding: `0 ${theme.spacing(0.5)}`,
}));

const Icon = styled(KeyboardArrowRight)(({ theme }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  color: theme.palette.text.primary,
}));

const Link = styled(MuiLink, {
  shouldForwardProp: prop => prop !== 'currentPath',
})<LinkProps & NavLinkProps & { currentPath?: boolean }>(
  ({ theme, currentPath }) => ({
    gap: '10px',
    padding: '0 4px',
    textDecoration: 'none',
    fontSize: theme.fontSize.sm,
    color: currentPath
      ? theme.palette.neutral[400]
      : theme.palette.neutral[700],
    '&:hover': {
      textDecoration: 'none',
    },
  }),
);

const Breadcrumbs = ({ nav = [] }) => (
  <BreadcrumbsContainer separator={<Icon />} aria-label='breadcrumbs'>
    {nav.map(({ label, path, onClick, loading = false }, index) => (
      <Link
        component={NavLink}
        key={index}
        to={path}
        currentPath={nav.length - 1 !== index}
        onClick={
          onClick &&
          (event => {
            event.preventDefault();
            onClick();
          })
        }
        relative='path'
      >
        <Skeleton
          variant='text'
          loading={loading}
          level='body-sm'
          sx={{ width: '196px', borderRadius: '8px' }}
        >
          {cutTitle(label, 60)}
        </Skeleton>
      </Link>
    ))}
  </BreadcrumbsContainer>
);

export default Breadcrumbs;
