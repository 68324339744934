import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  TransportOrganizationFilters,
  TransportOrganizationQuery,
} from './types';

export const transportOrganizationApi = createApi({
  reducerPath: 'transportOrganizationApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/transport-organizations`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getTransportOrganizations: builder.query<
      TransportOrganizationQuery[],
      TransportOrganizationFilters | void
    >({
      query: (data = {}) => ({
        url: '/query',
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const { useGetTransportOrganizationsQuery } = transportOrganizationApi;
