import { SkeletonProps } from '@mui/joy';

import { InfoBoxRow } from '@components/ui/InfoBox';
import { MapColumnSkeleton } from '@components/ui/skeletons/MapColumnSkeleton';
import { TextColumnSkeleton } from '@components/ui/skeletons/TextColumnSkeleton';

export const InformationSkeleton = ({
  loading,
  children,
  map,
}: SkeletonProps & { map?: boolean }) => {
  if (loading) {
    return (
      <InfoBoxRow>
        <TextColumnSkeleton sx={{ flex: map ? 3 : 1 }} />
        {map ? <MapColumnSkeleton sx={{ flex: 4 }} /> : <TextColumnSkeleton />}
      </InfoBoxRow>
    );
  }

  return children;
};
