import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { User } from '@/features/auth/types';
import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_AUTH_API_URL,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getUserById: builder.query<User, string>({
      query: id => ({
        url: `/accounts/${id}`,
      }),
    }),
  }),
});

export const { useGetUserByIdQuery } = authApi;
