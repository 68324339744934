import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { GasConsumingEquipmentAction } from './types';

export const gasConsumingEquipmentActionsApi = createApi({
  reducerPath: 'gasConsumingEquipmentActionsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/gas-consuming-equipment-actions`,
  }),
  tagTypes: ['GasConsumingEquipmentAction'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getGasConsumingEquipmentActions: builder.query<
      GasConsumingEquipmentAction[],
      void
    >({
      query: () => ({
        url: '',
      }),
    }),
  }),
});

export const { useGetGasConsumingEquipmentActionsQuery } =
  gasConsumingEquipmentActionsApi;
