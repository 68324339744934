import { ReactNode } from 'react';

import { NavLinkProps, Link as RouterLink } from 'react-router-dom';

import { styled } from '@mui/joy';
import Link, { LinkProps } from '@mui/joy/Link';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

export const SoftBox = styled(Stack)(({ theme }) => ({
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flex: 1,
  borderRadius: theme.spacing(1),
  border: `0.5px solid ${theme.palette.neutral[200]}`,
  background: theme.palette.neutral[50],
  padding: theme.spacing(2),
}));

export const SoftBoxLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.fontSize.sm,
  fontWeight: 600,
  color: theme.palette.neutral[600],
}));

export const StyledLink = styled(Link)<LinkProps & NavLinkProps>(
  ({ theme }) => ({
    cursor: 'pointer',
    padding: `${theme.spacing(0.5)} 0`,
    fontSize: theme.fontSize.sm,
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'none',
    },
  }),
);

export const SoftBoxLink = ({
  to,
  children,
  endDecorator,
}: NavLinkProps & LinkProps) => (
  <StyledLink component={RouterLink} to={to} endDecorator={endDecorator}>
    {children as string}
  </StyledLink>
);

export const SoftBoxField = ({
  label,
  children,
}: {
  label: string;
  children: ReactNode;
}) => (
  <Stack flex={1}>
    <SoftBoxLabel>{label}</SoftBoxLabel>
    {children}
  </Stack>
);
