import { FC } from 'react';

import { NavLink } from 'react-router-dom';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { IconButton, styled } from '@mui/joy';

const StyledDocumentLink = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

type DocumentLinkProps = {
  name: string;
  url: string;
};
export const DocumentLink: FC<DocumentLinkProps> = ({ name, url }) => (
  <StyledDocumentLink>
    <IconButton
      to={url}
      target='_blank'
      onClick={e => e.stopPropagation()}
      sx={{
        borderRadius: '100%',
        backgroundColor: 'primary.50',
        height: '40px',
        width: '40px',
      }}
      component={NavLink}
    >
      <InsertDriveFileIcon color='primary' fontSize='medium' />
    </IconButton>
    {name}
  </StyledDocumentLink>
);
