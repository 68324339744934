import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { actsAndDocumentsSlice } from '@/features/actsAndDocuments/actsAndDocumentsSlice';
import { admissionClassesApi } from '@/features/admissionClasses/admissionClassesApi';
import { allocationTypesApi } from '@/features/allocationTypes/allocationTypesApi';
import { authApi } from '@/features/auth/authApi';
import authSlice from '@/features/auth/authSlice';
import { balanceActsApi } from '@/features/balanceActs';
import { bridgeActsApi } from '@/features/bridgeActs/bridgeActsApi';
import { commercialMeteringPointsApi } from '@/features/commercialMeteringPoint/commercialMeteringPointsApi';
import { commercialMeteringPointOwnershipsApi } from '@/features/commercialMeteringPointOwnerships/commercialMeteringPointOwnershipsApi';
import { connectedPowerCalculatorApi } from '@/features/connectedPowerCalculator/connectedPowerCalculatorApi';
import { consumptionCategoryKindsApi } from '@/features/consumptionCategoryKinds/consumptionCategoryKindsApi';
import { contractsApi } from '@/features/contracts/contractsApi';
import { counterpartRequestsApi } from '@/features/counterpartRequests/counterpartRequestsApi';
import { counterpartRequestsActionApi } from '@/features/counterpartRequestsAction/counterpartRequestsActionApi';
import { counterpartiesApi } from '@/features/counterparties/counterparties';
import { counterpartyDocumentTypesAp } from '@/features/counterpartyDocumentTypes/counterpartyDocumentTypesApi';
import { deviceStatesApi } from '@/features/deviceStates/deviceStatesApi';
import { disconnectionReasonsApi } from '@/features/disconnectionReasons/disconnectionReasonsApi';
import { economicSectorObjectTypesApi } from '@/features/economicSectorObjectTypes/economicSectorObjectTypesApi';
import { employeeStatusesApi } from '@/features/employees/employeeStatusesApi';
import { employeesApi } from '@/features/employees/employeesApi';
import { equipmentGroupApi } from '@/features/equipmentGroup/equipmentGroupApi';
import { equipmentManufacturerApi } from '@/features/equipmentManufacturer/equipmentManufacturerApi';
import { equipmentTypeApi } from '@/features/equipmentType/equipmentTypeApi';
import { formNineApi } from '@/features/formNine/formNineApi';
import { gasConsumingEquipmentApi } from '@/features/gasConsumingEquipment/gasConsumingEquipmentApi';
import { gasConsumingEquipmentActionsApi } from '@/features/gasConsumingEquipmentActions/gasConsumingEquipmentActionsApi';
import { gasDistributionStationCategoryApi } from '@/features/gasDistributionStationCategory';
import { gasDistributionStationTypeApi } from '@/features/gasDistributionStationType';
import { gasDistributionStationsApi } from '@/features/gasDistributionStations/gasDistributionStationsApi';
import { gasPipelineSectionGroupsApi } from '@/features/gasPipelineSectionGroups';
import { gasPipelineSectionLocationsApi } from '@/features/gasPipelineSectionLocations';
import { gasPipelineSectionTypesApi } from '@/features/gasPipelineSectionTypes';
import { gasPipelineSectionsApi } from '@/features/gasPipelineSections/gasPipelineSectionsApi';
import { gasRoutePhysicoChemicalIndicatorApi } from '@/features/gasRoutePhysicoChemicalIndicator/gasRoutePhysicoChemicalIndicatorApi';
import { gasRouteTypesApi } from '@/features/gasRouteTypes/gasRouteTypesApi';
import { gasRoutesApi } from '@/features/gasRoutes/gasRoutesApi';
import { gasThreadApi } from '@/features/gasThread/gasThreadApi';
import { gasThreadPhysicoChemicalIndicatorApi } from '@/features/gasThreadPhysicoChemicalIndicator/gasThreadPhysicoChemicalIndicatorApi';
import { gdsPhysicoChemicalIndicatorApi } from '@/features/gdsPhysicoChemicalIndicator/gdsPhysicoChemicalIndicatorApi';
import { locationsApi } from '@/features/locations/locationsApi';
import { mainOrganizationApi } from '@/features/mainOrganization/mainOrganizationApi';
import { measurementPeriodsApi } from '@/features/measurementPeriods/measurementPeriodsApi';
import { measurementRangesApi } from '@/features/measurementRanges/measurementRangesApi';
import { meterLocationsApi } from '@/features/meterLocations/meterLocationsApi';
import { meterThroughputsApi } from '@/features/meterThroughputs/meterThroughputsApi';
import { meteringDeviceActionsApi } from '@/features/meteringDeviceActions/meteringDeviceActionsApi';
import { meteringDeviceGroupsApi } from '@/features/meteringDeviceGroups/meteringDevicePassportTypeApi';
import { meteringDevicePassportTypeApi } from '@/features/meteringDevicePassportType/meteringDevicePassportTypeApi';
import { meteringDeviceTypeApi } from '@/features/meteringDeviceType/meteringDeviceTypeApi';
import { meteringDevicesApi } from '@/features/meteringDevices/meteringDevicesApi';
import { meteringModemApi } from '@/features/meteringModem/meteringModemApi';
import { meteringModemActionsApi } from '@/features/meteringModemActions/meteringModemActionsApi';
import { meteringModemTypeApi } from '@/features/meteringModemType/meteringModemTypeApi';
import { meteringNodeApi } from '@/features/meteringNode/meteringNodeApi';
import { meteringNodeKindApi } from '@/features/meteringNodeKind/meteringNodeKindApi';
import { meteringNodeLocationTypeApi } from '@/features/meteringNodeLocationType/meteringNodeLocationTypeApi';
import { meteringNodeTypeApi } from '@/features/meteringNodeType/meteringNodeTypeApi';
import { meteringPointApi } from '@/features/meteringPoint/meteringPointApi';
import { meteringPointActStepValidationApi } from '@/features/meteringPointActStepValidation/meteringPointActStepValidationApi';
import { meteringPointActTypesApi } from '@/features/meteringPointActTypes/meteringPointActTypesApi';
import { meteringPointActionsApi } from '@/features/meteringPointActions/meteringPointActionsApi';
import { meteringPointActApi } from '@/features/meteringPointActs/meteringPointActApi';
import { meteringPointActualIncomeApi } from '@/features/meteringPointActualIncome';
import { meteringPointAdjustmentApi } from '@/features/meteringPointAdjustment/meteringPointAdjustmentApi';
import { meteringPointConnectionTypesApi } from '@/features/meteringPointConnectionTypes/meteringPointConnectionTypesApi';
import { meteringPointDisconnectionReasonsApi } from '@/features/meteringPointDisconnectionReasons/meteringPointDisconnectionReasonsApi';
import { meteringPointStatusesApi } from '@/features/meteringPointStatuses/meteringPointStatusesApi';
import { meteringPointTechnicalCalculationApi } from '@/features/meteringPointTechnicalCalculation/meteringPointTechnicalCalculationApi';
import { meteringPointTypeApi } from '@/features/meteringPointType/meteringPointApi';
import { meteringSealApi } from '@/features/meteringSeal/meteringSealApi';
import { networkTypeApi } from '@/features/networkType/networkTypeApi';
import { nodeSealLocationApi } from '@/features/nodeSealLocation/nodeSealLocationApi';
import { nodeSealTypesApi } from '@/features/nodeSealTypes/nodeSealTypesApi';
import { operatingGasRoutesApi } from '@/features/operatingGasRoutes/operatingGasRoutesApi';
import { operatingIncomesApi } from '@/features/operatingIncomes/operatingIncomesApi';
import { operationalExpenseApi } from '@/features/operationalExpense';
import { organizationsApi } from '@/features/organizations/organizationsApi';
import { outageTypesApi } from '@/features/outageTypes/outageTypesApi';
import { ownDemandTypeApi } from '@/features/ownDemandType/ownDemandTypeApi';
import { pipelineSectionActionsApi } from '@/features/pipelineSectionActions/pipelineSectionActionsApi';
import { pressureCategoriesApi } from '@/features/pressureCategories';
import { rolesApi } from '@/features/roles/rolesApi';
import { sealActionsApi } from '@/features/sealActions/sealActionsApi';
import { seasonTypesApi } from '@/features/seasonTypes/seasonTypesApi';
import { sectorBridgeActionsApi } from '@/features/sectorBridgeActions/sectorBridgeActionsApi';
import { sectorBridgeStatusesApi } from '@/features/sectorBridgeStatuses/sectorBridgeStatusesApi';
import { sectorBridgesApi } from '@/features/sectorBridges/sectorBridgesApi';
import { sectorsApi } from '@/features/sectors/sectorsApi';
import { serviceOrganizationsApi } from '@/features/serviceOrganizations/serviceOrganizationsApi';
import { subdivisionsApi } from '@/features/subdivisions/subdivisionsApi';
import { transportOrganizationApi } from '@/features/transportOrganization/transportOrganizationApi';
import uiSlice from '@/features/ui/uiSlice';
import { usersApi } from '@/features/users/usersApi';
import usersSlice from '@/features/users/usersSlice';

const rootReducer = combineReducers({
  [uiSlice.name]: uiSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
  [subdivisionsApi.reducerPath]: subdivisionsApi.reducer,
  [employeeStatusesApi.reducerPath]: employeeStatusesApi.reducer,
  [employeesApi.reducerPath]: employeesApi.reducer,
  [counterpartiesApi.reducerPath]: counterpartiesApi.reducer,
  [meteringNodeApi.reducerPath]: meteringNodeApi.reducer,
  [meteringNodeTypeApi.reducerPath]: meteringNodeTypeApi.reducer,
  [meteringNodeLocationTypeApi.reducerPath]:
    meteringNodeLocationTypeApi.reducer,
  [seasonTypesApi.reducerPath]: seasonTypesApi.reducer,
  [meteringNodeKindApi.reducerPath]: meteringNodeKindApi.reducer,
  [commercialMeteringPointsApi.reducerPath]:
    commercialMeteringPointsApi.reducer,
  [usersSlice.name]: usersSlice.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [gasDistributionStationsApi.reducerPath]: gasDistributionStationsApi.reducer,
  [gasDistributionStationTypeApi.reducerPath]:
    gasDistributionStationTypeApi.reducer,
  [gasDistributionStationCategoryApi.reducerPath]:
    gasDistributionStationCategoryApi.reducer,
  [gasThreadApi.reducerPath]: gasThreadApi.reducer,
  [networkTypeApi.reducerPath]: networkTypeApi.reducer,
  [meteringPointApi.reducerPath]: meteringPointApi.reducer,
  [meteringPointTypeApi.reducerPath]: meteringPointTypeApi.reducer,
  [sectorsApi.reducerPath]: sectorsApi.reducer,
  [sectorBridgesApi.reducerPath]: sectorBridgesApi.reducer,
  [sectorBridgeActionsApi.reducerPath]: sectorBridgeActionsApi.reducer,
  [sectorBridgeStatusesApi.reducerPath]: sectorBridgeStatusesApi.reducer,
  [bridgeActsApi.reducerPath]: bridgeActsApi.reducer,
  [ownDemandTypeApi.reducerPath]: ownDemandTypeApi.reducer,
  [locationsApi.reducerPath]: locationsApi.reducer,
  [gasRoutesApi.reducerPath]: gasRoutesApi.reducer,
  [gasRouteTypesApi.reducerPath]: gasRouteTypesApi.reducer,
  [operatingGasRoutesApi.reducerPath]: operatingGasRoutesApi.reducer,
  [transportOrganizationApi.reducerPath]: transportOrganizationApi.reducer,
  [mainOrganizationApi.reducerPath]: mainOrganizationApi.reducer,
  [meteringSealApi.reducerPath]: meteringSealApi.reducer,
  [nodeSealTypesApi.reducerPath]: nodeSealTypesApi.reducer,
  [nodeSealLocationApi.reducerPath]: nodeSealLocationApi.reducer,
  [meteringDevicesApi.reducerPath]: meteringDevicesApi.reducer,
  [meteringModemApi.reducerPath]: meteringModemApi.reducer,
  [meteringModemTypeApi.reducerPath]: meteringModemTypeApi.reducer,
  [consumptionCategoryKindsApi.reducerPath]:
    consumptionCategoryKindsApi.reducer,
  [economicSectorObjectTypesApi.reducerPath]:
    economicSectorObjectTypesApi.reducer,
  [measurementPeriodsApi.reducerPath]: measurementPeriodsApi.reducer,
  [pressureCategoriesApi.reducerPath]: pressureCategoriesApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [meteringDevicePassportTypeApi.reducerPath]:
    meteringDevicePassportTypeApi.reducer,
  [meteringDeviceTypeApi.reducerPath]: meteringDeviceTypeApi.reducer,
  [admissionClassesApi.reducerPath]: admissionClassesApi.reducer,
  [meterLocationsApi.reducerPath]: meterLocationsApi.reducer,
  [measurementRangesApi.reducerPath]: measurementRangesApi.reducer,
  [meterThroughputsApi.reducerPath]: meterThroughputsApi.reducer,
  [meteringDeviceGroupsApi.reducerPath]: meteringDeviceGroupsApi.reducer,
  [gasConsumingEquipmentApi.reducerPath]: gasConsumingEquipmentApi.reducer,
  [equipmentGroupApi.reducerPath]: equipmentGroupApi.reducer,
  [equipmentManufacturerApi.reducerPath]: equipmentManufacturerApi.reducer,
  [equipmentTypeApi.reducerPath]: equipmentTypeApi.reducer,
  [contractsApi.reducerPath]: contractsApi.reducer,
  [serviceOrganizationsApi.reducerPath]: serviceOrganizationsApi.reducer,
  [meteringPointStatusesApi.reducerPath]: meteringPointStatusesApi.reducer,
  [formNineApi.reducerPath]: formNineApi.reducer,
  [operatingIncomesApi.reducerPath]: operatingIncomesApi.reducer,
  [disconnectionReasonsApi.reducerPath]: disconnectionReasonsApi.reducer,
  [commercialMeteringPointOwnershipsApi.reducerPath]:
    commercialMeteringPointOwnershipsApi.reducer,
  [meteringPointActApi.reducerPath]: meteringPointActApi.reducer,
  [meteringPointActTypesApi.reducerPath]: meteringPointActTypesApi.reducer,
  [counterpartRequestsApi.reducerPath]: counterpartRequestsApi.reducer,
  [counterpartRequestsActionApi.reducerPath]:
    counterpartRequestsActionApi.reducer,
  [counterpartyDocumentTypesAp.reducerPath]:
    counterpartyDocumentTypesAp.reducer,
  [gasPipelineSectionsApi.reducerPath]: gasPipelineSectionsApi.reducer,
  [gasRoutePhysicoChemicalIndicatorApi.reducerPath]:
    gasRoutePhysicoChemicalIndicatorApi.reducer,
  [gdsPhysicoChemicalIndicatorApi.reducerPath]:
    gdsPhysicoChemicalIndicatorApi.reducer,
  [gasThreadPhysicoChemicalIndicatorApi.reducerPath]:
    gasThreadPhysicoChemicalIndicatorApi.reducer,
  [meteringPointActionsApi.reducerPath]: meteringPointActionsApi.reducer,
  [meteringPointDisconnectionReasonsApi.reducerPath]:
    meteringPointDisconnectionReasonsApi.reducer,
  [meteringPointConnectionTypesApi.reducerPath]:
    meteringPointConnectionTypesApi.reducer,
  [outageTypesApi.reducerPath]: outageTypesApi.reducer,
  [meteringDeviceActionsApi.reducerPath]: meteringDeviceActionsApi.reducer,
  [deviceStatesApi.reducerPath]: deviceStatesApi.reducer,
  [gasConsumingEquipmentActionsApi.reducerPath]:
    gasConsumingEquipmentActionsApi.reducer,
  [sealActionsApi.reducerPath]: sealActionsApi.reducer,
  [connectedPowerCalculatorApi.reducerPath]:
    connectedPowerCalculatorApi.reducer,
  [actsAndDocumentsSlice.name]: actsAndDocumentsSlice.reducer,
  [meteringModemActionsApi.reducerPath]: meteringModemActionsApi.reducer,
  [operationalExpenseApi.reducerPath]: operationalExpenseApi.reducer,
  [meteringPointActStepValidationApi.reducerPath]:
    meteringPointActStepValidationApi.reducer,
  [balanceActsApi.reducerPath]: balanceActsApi.reducer,
  [gasPipelineSectionLocationsApi.reducerPath]:
    gasPipelineSectionLocationsApi.reducer,
  [gasPipelineSectionTypesApi.reducerPath]: gasPipelineSectionTypesApi.reducer,
  [gasPipelineSectionGroupsApi.reducerPath]:
    gasPipelineSectionGroupsApi.reducer,
  [pipelineSectionActionsApi.reducerPath]: pipelineSectionActionsApi.reducer,
  [allocationTypesApi.reducerPath]: allocationTypesApi.reducer,
  [meteringPointTechnicalCalculationApi.reducerPath]:
    meteringPointTechnicalCalculationApi.reducer,
  [meteringPointAdjustmentApi.reducerPath]: meteringPointAdjustmentApi.reducer,
  [meteringPointActualIncomeApi.reducerPath]:
    meteringPointActualIncomeApi.reducer,
});

const middlewares = [
  authApi.middleware,
  organizationsApi.middleware,
  subdivisionsApi.middleware,
  usersApi.middleware,
  gasDistributionStationsApi.middleware,
  gasDistributionStationTypeApi.middleware,
  gasDistributionStationCategoryApi.middleware,
  gasThreadApi.middleware,
  networkTypeApi.middleware,
  meteringPointApi.middleware,
  meteringPointTypeApi.middleware,
  sectorsApi.middleware,
  gasPipelineSectionLocationsApi.middleware,
  gasPipelineSectionTypesApi.middleware,
  sectorBridgesApi.middleware,
  bridgeActsApi.middleware,
  sectorBridgeActionsApi.middleware,
  sectorBridgeStatusesApi.middleware,
  ownDemandTypeApi.middleware,
  locationsApi.middleware,
  employeesApi.middleware,
  employeeStatusesApi.middleware,
  counterpartiesApi.middleware,
  meteringNodeApi.middleware,
  gasRoutesApi.middleware,
  gasRouteTypesApi.middleware,
  operatingGasRoutesApi.middleware,
  transportOrganizationApi.middleware,
  seasonTypesApi.middleware,
  meteringNodeKindApi.middleware,
  meteringNodeTypeApi.middleware,
  meteringNodeLocationTypeApi.middleware,
  rolesApi.middleware,
  mainOrganizationApi.middleware,
  meteringSealApi.middleware,
  nodeSealTypesApi.middleware,
  nodeSealLocationApi.middleware,
  meteringDevicesApi.middleware,
  meteringModemApi.middleware,
  meteringModemTypeApi.middleware,
  commercialMeteringPointsApi.middleware,
  consumptionCategoryKindsApi.middleware,
  economicSectorObjectTypesApi.middleware,
  measurementPeriodsApi.middleware,
  pressureCategoriesApi.middleware,
  serviceOrganizationsApi.middleware,
  meteringDevicePassportTypeApi.middleware,
  meteringDeviceTypeApi.middleware,
  admissionClassesApi.middleware,
  meterLocationsApi.middleware,
  measurementRangesApi.middleware,
  meterThroughputsApi.middleware,
  meteringDeviceGroupsApi.middleware,
  gasConsumingEquipmentApi.middleware,
  equipmentGroupApi.middleware,
  equipmentManufacturerApi.middleware,
  equipmentTypeApi.middleware,
  contractsApi.middleware,
  meteringPointStatusesApi.middleware,
  formNineApi.middleware,
  commercialMeteringPointOwnershipsApi.middleware,
  operatingIncomesApi.middleware,
  meteringPointActApi.middleware,
  meteringPointActTypesApi.middleware,
  counterpartRequestsApi.middleware,
  counterpartRequestsActionApi.middleware,
  counterpartyDocumentTypesAp.middleware,
  disconnectionReasonsApi.middleware,
  gasPipelineSectionsApi.middleware,
  gasRoutePhysicoChemicalIndicatorApi.middleware,
  gdsPhysicoChemicalIndicatorApi.middleware,
  gasThreadPhysicoChemicalIndicatorApi.middleware,
  meteringPointActionsApi.middleware,
  meteringPointDisconnectionReasonsApi.middleware,
  meteringPointConnectionTypesApi.middleware,
  outageTypesApi.middleware,
  meteringDeviceActionsApi.middleware,
  deviceStatesApi.middleware,
  gasConsumingEquipmentActionsApi.middleware,
  sealActionsApi.middleware,
  connectedPowerCalculatorApi.middleware,
  meteringModemActionsApi.middleware,
  operationalExpenseApi.middleware,
  meteringPointActStepValidationApi.middleware,
  balanceActsApi.middleware,
  gasPipelineSectionGroupsApi.middleware,
  pipelineSectionActionsApi.middleware,
  allocationTypesApi.middleware,
  meteringPointTechnicalCalculationApi.middleware,
  meteringPointAdjustmentApi.middleware,
  meteringPointActualIncomeApi.middleware,
];

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(middlewares),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
