import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import { ReferenceBook } from '@/features/organizations/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const measurementPeriodsApi = createApi({
  reducerPath: 'measurementPeriodsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/measurement-periods`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => {
    return {
      getMeasurementPeriods: builder.query<ReferenceBook[], void>({
        query: () => ({
          url: ``,
        }),
      }),
    };
  },
});

export const { useGetMeasurementPeriodsQuery } = measurementPeriodsApi;
