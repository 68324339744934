import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import { MeteringPointActItemsParams } from '@/features/meteringPointActs/types/MeteringPointActItemsParams';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  MeteringPointAct,
  MeteringPointActDevice,
  MeteringPointActEquipment,
  MeteringPointActFilters,
  MeteringPointActModem,
  MeteringPointActPipelineSection,
  MeteringPointActQuery,
  MeteringPointActSeal,
} from './types';

export const meteringPointActApi = createApi({
  reducerPath: 'meteringPointActApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL + '/metering-point-acts',
  }),
  tagTypes: [
    'MeteringPointAct',
    'MeteringPointActs',
    'MeteringPointActDevice',
    'MeteringPointActEquipment',
    'MeteringPointActModem',
    'MeteringPointActPipelineSection',
    'MeteringPointActSeal',
  ],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getMeteringPointActs: builder.query<
      MeteringPointActQuery[],
      MeteringPointActFilters | void
    >({
      query: (data = {}) => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: ['MeteringPointAct', 'MeteringPointActs'],
    }),
    getMeteringPointActById: builder.query<MeteringPointAct, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: ['MeteringPointAct'],
    }),
    createMeteringPointAct: builder.mutation<MeteringPointAct, FormData>({
      query: data => ({
        url: '/',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data,
      }),
    }),
    updateMeteringPointAct: builder.mutation<MeteringPointAct, FormData>({
      query: data => ({
        url: `/${data.get('id')}`,
        method: 'PUT',
        data,
      }),
    }),
    deleteMeteringPointActById: builder.mutation<MeteringPointAct, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [
        'MeteringPointAct',
        { type: 'MeteringPointAct', id },
      ],
    }),
    getMeteringPointActDevices: builder.query<
      MeteringPointActDevice[],
      MeteringPointActItemsParams
    >({
      query: params => ({
        url: `/devices`,
        params,
      }),
      providesTags: ['MeteringPointActDevice'],
    }),
    getMeteringPointActEquipment: builder.query<
      MeteringPointActEquipment[],
      MeteringPointActItemsParams
    >({
      query: params => ({
        url: `/equipment`,
        params,
      }),
      providesTags: ['MeteringPointActEquipment'],
    }),
    getMeteringPointActModems: builder.query<
      MeteringPointActModem[],
      MeteringPointActItemsParams
    >({
      query: params => ({
        url: `/modems`,
        params,
      }),
      providesTags: ['MeteringPointActModem'],
    }),
    getMeteringPointActPipelineSections: builder.query<
      MeteringPointActPipelineSection[],
      MeteringPointActItemsParams
    >({
      query: params => ({
        url: `/pipeline-sections`,
        params,
      }),
      providesTags: ['MeteringPointActPipelineSection'],
    }),
    getMeteringPointActSeals: builder.query<
      MeteringPointActSeal[],
      MeteringPointActItemsParams
    >({
      query: params => ({
        url: `/seals`,
        params,
      }),
      providesTags: ['MeteringPointActSeal'],
    }),
  }),
});

export const {
  useGetMeteringPointActsQuery,
  useGetMeteringPointActByIdQuery,
  useCreateMeteringPointActMutation,
  useUpdateMeteringPointActMutation,
  useDeleteMeteringPointActByIdMutation,
  useGetMeteringPointActDevicesQuery,
  useGetMeteringPointActModemsQuery,
  useGetMeteringPointActEquipmentQuery,
  useGetMeteringPointActPipelineSectionsQuery,
  useGetMeteringPointActSealsQuery,
} = meteringPointActApi;
