import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeteringPointAdjustment } from './types';

export const meteringPointAdjustmentApi = createApi({
  reducerPath: 'meteringPointAdjustmentApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}/metering-point-adjustments`,
  }),
  tagTypes: ['MeteringPointAdjustments', 'MeteringPointAdjustment'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getMeteringPointAdjustment: builder.query<MeteringPointAdjustment[], any>({
      query: data => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: ['MeteringPointAdjustment', 'MeteringPointAdjustments'],
    }),
    getMeteringPointAdjustmentById: builder.query<
      MeteringPointAdjustment,
      string
    >({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: ['MeteringPointAdjustment', 'MeteringPointAdjustments'],
    }),
    createMeteringPointAdjustment: builder.mutation<
      MeteringPointAdjustment,
      MeteringPointAdjustment
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringPointAdjustment',
        { type: 'MeteringPointAdjustment', id: result.id },
      ],
    }),
    updateMeteringPointAdjustmentById: builder.mutation<
      MeteringPointAdjustment,
      MeteringPointAdjustment
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: result => [
        'MeteringPointAdjustment',
        { type: 'MeteringPointAdjustment', id: result.id },
      ],
    }),
    deleteMeteringPointAdjustmentById: builder.mutation<
      MeteringPointAdjustment,
      string
    >({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: result => [
        'MeteringPointAdjustments',
        { type: 'MeteringPointAdjustment', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetMeteringPointAdjustmentQuery,
  useCreateMeteringPointAdjustmentMutation,
  useGetMeteringPointAdjustmentByIdQuery,
  useUpdateMeteringPointAdjustmentByIdMutation,
  useDeleteMeteringPointAdjustmentByIdMutation,
} = meteringPointAdjustmentApi;
