import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  ActualIncomeDocumentReadModelSlim,
  MeteringPointActualIncomeAggregatedReadModel,
  MeteringPointActualIncomeQueryModel,
  MeteringPointActualIncomeValidateModel,
  MeteringPointActualIncomesDocument,
  MeteringPointActualIncomesDocumentsParams,
  MeteringPointDailyActualIncomeCreateModel,
  MeteringPointDailyActualIncomeQueryModel,
  MeteringPointDailyActualIncomeReadModel,
  MeteringPointDailyActualIncomeUpdateModel,
  MeteringPointsActualIncomeQueryModel,
} from './types';

export const meteringPointActualIncomeApi = createApi({
  reducerPath: 'meteringPointActualIncomeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-point-actual-incomes`,
  }),
  tagTypes: ['MeteringPointActualIncome'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    queryByMeteringPoints: builder.query<
      ActualIncomeDocumentReadModelSlim[],
      MeteringPointsActualIncomeQueryModel
    >({
      query: model => ({
        url: '/query',
        method: 'POST',
        data: model,
      }),
    }),
    queryByMeteringPoint: builder.query<
      MeteringPointActualIncomeAggregatedReadModel,
      MeteringPointActualIncomeQueryModel
    >({
      query: model => ({
        url: '/metering-points/query',
        method: 'POST',
        data: model,
      }),
    }),
    getMeteringPointDailyActualIncome: builder.query<
      MeteringPointDailyActualIncomeReadModel,
      MeteringPointDailyActualIncomeQueryModel
    >({
      query: params => ({
        url: '/metering-points/daily-income',
        params,
      }),
    }),
    addMeteringPointDailyActualIncome: builder.mutation<
      string,
      MeteringPointDailyActualIncomeCreateModel
    >({
      query: params => ({
        url: '/metering-points/daily-income',
        method: 'POST',
        params,
      }),
    }),
    updateMeteringPointDailyActualIncome: builder.mutation<
      void,
      MeteringPointDailyActualIncomeUpdateModel
    >({
      query: ({ id, ...params }) => ({
        url: `/metering-points/daily-income/${id}`,
        method: 'PUT',
        params,
      }),
    }),
    validateActualIncome: builder.mutation<
      void,
      MeteringPointActualIncomeValidateModel
    >({
      query: data => ({
        url: '/validate',
        method: 'POST',
        data,
      }),
    }),
    getActualIncomeDocument: builder.query<
      MeteringPointActualIncomesDocument,
      MeteringPointActualIncomesDocumentsParams
    >({
      query: params => ({
        url: '/documents',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useQueryByMeteringPointsQuery,
  useQueryByMeteringPointQuery,
  useGetMeteringPointDailyActualIncomeQuery,
  useAddMeteringPointDailyActualIncomeMutation,
  useUpdateMeteringPointDailyActualIncomeMutation,
  useValidateActualIncomeMutation,
  useGetActualIncomeDocumentQuery,
} = meteringPointActualIncomeApi;
