import { Skeleton, SkeletonProps } from '@mui/joy';

import { TextSkeleton } from '@components/ui/skeletons/TextSkeleton';
import { Column } from '@components/ui/skeletons/styled';

export const mapStyles = {
  width: '100%',
  borderRadius: '8px',
  aspectRatio: 612 / 461,
  marginTop: '16px',
};

export const MapSkeleton = ({ sx = {} }: SkeletonProps) => (
  <Skeleton
    variant='rectangular'
    level='title-sm'
    sx={{
      ...mapStyles,
      ...sx,
    }}
  />
);

export const MapColumnSkeleton = ({ sx }: SkeletonProps) => {
  return (
    <Column sx={sx}>
      <TextSkeleton width='116px' />
      <MapSkeleton />
    </Column>
  );
};
