import { ReactNode } from 'react';

import Label, { FormLabelProps as Props } from '@mui/joy/FormLabel';

interface FormLabelProps extends Props {
  children: ReactNode;
  required?: boolean;
  preview?: boolean;
  disabled?: boolean;
}

export const FormLabel = ({
  children,
  required,
  preview,
  disabled,
  sx = {},
}: FormLabelProps) => {
  return (
    <Label
      sx={{
        margin: 0,
        fontWeight: preview ? 400 : 500,
        fontSize: 14,
        color: disabled
          ? 'neutral.400'
          : preview
            ? 'neutral.700'
            : 'neutral.800',
        ...sx,
      }}
    >
      {children}{' '}
      {!preview && required && (
        <span style={{ color: disabled ? 'neutral.400' : 'red' }}>*</span>
      )}
    </Label>
  );
};
