import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import { MeteringPointActRequestBody } from '@/features/meteringPointActs/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const meteringPointActStepValidationApi = createApi({
  reducerPath: 'meteringPointActStepValidationApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-point-acts`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    validateCreate: builder.mutation<boolean, FormData>({
      query: formData => ({
        url: '/validate-create',
        method: 'POST',
        data: formData,
      }),
    }),
    validateUpdate: builder.mutation<boolean, FormData>({
      query: formData => ({
        url: `/${formData.get('id')}/validate-update`,
        method: 'POST',
        data: formData,
      }),
    }),
    validateCreateDevicesAndModems: builder.mutation<
      boolean,
      Partial<MeteringPointActRequestBody>
    >({
      query: data => ({
        url: '/validate-devices-and-modems',
        method: 'POST',
        data,
      }),
    }),
    validateUpdateDevicesAndModems: builder.mutation<
      boolean,
      Partial<MeteringPointActRequestBody>
    >({
      query: data => ({
        url: `/${data.id}/validate-devices-and-modems`,
        method: 'POST',
        data,
      }),
    }),
    validateCreateEquipment: builder.mutation<
      boolean,
      Partial<MeteringPointActRequestBody>
    >({
      query: data => ({
        url: '/validate-equipment',
        method: 'POST',
        data,
      }),
    }),
    validateUpdateEquipment: builder.mutation<
      boolean,
      Partial<MeteringPointActRequestBody>
    >({
      query: data => ({
        url: `/${data.id}/validate-equipment`,
        method: 'POST',
        data,
      }),
    }),
    validateCreateSeals: builder.mutation<
      boolean,
      Partial<MeteringPointActRequestBody>
    >({
      query: data => ({
        url: '/validate-seals',
        method: 'POST',
        data,
      }),
    }),
    validateUpdateSeals: builder.mutation<
      boolean,
      Partial<MeteringPointActRequestBody>
    >({
      query: data => ({
        url: `/${data.id}/validate-seals`,
        method: 'POST',
        data,
      }),
    }),
    validateCreatePipelineSections: builder.mutation<
      boolean,
      Partial<MeteringPointActRequestBody>
    >({
      query: data => ({
        url: '/validate-pipeline-sections',
        method: 'POST',
        data,
      }),
    }),
    validateUpdatePipelineSections: builder.mutation<
      boolean,
      Partial<MeteringPointActRequestBody>
    >({
      query: data => ({
        url: `/${data.id}/validate-pipeline-sections`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  useValidateCreateMutation,
  useValidateUpdateMutation,
  useValidateCreateDevicesAndModemsMutation,
  useValidateUpdateDevicesAndModemsMutation,
  useValidateCreateEquipmentMutation,
  useValidateUpdateEquipmentMutation,
  useValidateCreateSealsMutation,
  useValidateUpdateSealsMutation,
  useValidateCreatePipelineSectionsMutation,
  useValidateUpdatePipelineSectionsMutation,
} = meteringPointActStepValidationApi;
