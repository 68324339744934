import { styled } from '@mui/joy';
import { ButtonProps, default as JoyButton } from '@mui/joy/Button';
import Skeleton from '@mui/joy/Skeleton';

export const ButtonSkeleton = styled(Skeleton)`
  position: absolute;
  inset: 0;
  border-radius: 5px;
  .MuiButton-variantOutlined &,
  .MuiButtonGroup-variantOutlined & {
    inset: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
  }
`;

export type ButtonWithSkeletonProps = ButtonProps & { skeleton?: boolean };

export const Button = ({
  children,
  skeleton,
  variant,
  ...props
}: ButtonWithSkeletonProps) => {
  return (
    <JoyButton {...props} variant={variant}>
      {skeleton && <ButtonSkeleton />}
      {children}
    </JoyButton>
  );
};
