import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

const NotFound = () => {
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Typography level='title-lg' fontSize='60px'>
        404
      </Typography>
      <Typography level='body-md'>Здається ви десь заблукали</Typography>
    </Stack>
  );
};

export default NotFound;
