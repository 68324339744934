import { FC, useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  makeSelectStepIsDirty,
  makeSelectStepIsValid,
} from '@/features/actsAndDocuments/actsAndDocumentsSlice';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/joy';
import Stack from '@mui/joy/Stack';

import {
  ActsAndDocumentsFormSteps,
  StepKey,
  useActsAndDocumentsFormContext,
} from '@components/ActsAndDocumentsForm';

const getColor = ({
  isActive,
  isInvalid,
  isEditMode,
  theme,
}: {
  isActive: boolean;
  isInvalid: boolean;
  isEditMode?: boolean;
  theme;
}) => {
  if (isInvalid) {
    return theme.palette.danger[500];
  }

  if (isActive) {
    return isEditMode ? theme.palette.success[500] : theme.palette.primary[500];
  }

  return '';
};

const StyledActFormStep = styled(Stack)<{
  isActive: boolean;
  isInvalid: boolean;
  isEditMode?: boolean;
}>(({ theme, isActive, isInvalid, isEditMode }) => ({
  flexDirection: 'row',
  gap: theme.spacing(1),
  color: getColor({ isActive, isInvalid, isEditMode, theme }),
}));

type ActFormStepProps = {
  title: string;
  step: StepKey<ActsAndDocumentsFormSteps>;
};

export const ActFormStep: FC<ActFormStepProps> = ({ title, step }) => {
  const { currentStep, isEditMode } = useActsAndDocumentsFormContext();

  const isValid = useSelector(makeSelectStepIsValid(step));
  const isDirty = useSelector(makeSelectStepIsDirty(step));

  const isActive = useMemo(() => currentStep === step, [currentStep, step]);
  const isChecked = useMemo(() => isDirty && isValid, [isDirty, isValid]);

  return (
    <StyledActFormStep
      isActive={isActive}
      isInvalid={!isValid}
      isEditMode={isEditMode}
      role='button'
    >
      {isChecked ? <CheckIcon /> : <AddIcon />}
      {title}
    </StyledActFormStep>
  );
};
