import { Children, FC, Fragment, PropsWithChildren } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/joy';
import Stack from '@mui/joy/Stack';

const StyledStepsContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
}));

type ActFormStepsContainerProps = {} & PropsWithChildren;

const ActFormStepsDelimiter = () => <ChevronRightIcon fontSize='small' />;

export const ActFormStepsContainer: FC<ActFormStepsContainerProps> = ({
  children,
}) => {
  const childrenArray = Children.toArray(children);

  return (
    <StyledStepsContainer>
      {childrenArray.map((child, index) => (
        <Fragment key={index}>
          {child}
          {index < childrenArray.length - 1 && <ActFormStepsDelimiter />}
        </Fragment>
      ))}
    </StyledStepsContainer>
  );
};
