import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  GasRoutePhysicoChemicalIndicator,
  GasRoutePhysicoChemicalIndicatorFilters,
  GasRoutePhysicoChemicalIndicatorForm,
} from './types';

export const gasRoutePhysicoChemicalIndicatorApi = createApi({
  reducerPath: 'gasRoutePhysicoChemicalIndicatorApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/gas-route-physico-chemical-indicators`,
  }),
  tagTypes: [
    'GasRoutePhysicoChemicalIndicators',
    'GasRoutePhysicoChemicalIndicator',
  ],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getGasRoutePhysicoChemicalIndicator: builder.query<
      GasRoutePhysicoChemicalIndicator[],
      GasRoutePhysicoChemicalIndicatorFilters | void
    >({
      query: filters => ({
        url: `/query`,
        method: 'POST',
        data: filters || {},
      }),
      providesTags: ['GasRoutePhysicoChemicalIndicator'],
    }),
    getGasRoutePhysicoChemicalIndicatorById: builder.query<
      GasRoutePhysicoChemicalIndicator,
      string
    >({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: result => [
        { type: 'GasRoutePhysicoChemicalIndicator', id: result.id },
      ],
    }),
    createGasRoutePhysicoChemicalIndicator: builder.mutation<
      string,
      GasRoutePhysicoChemicalIndicatorForm
    >({
      query: data => ({
        url: ``,
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'GasRoutePhysicoChemicalIndicators',
        { type: 'GasRoutePhysicoChemicalIndicator', id: result },
      ],
    }),
    updateGasRoutePhysicoChemicalIndicatorById: builder.mutation<
      string,
      GasRoutePhysicoChemicalIndicatorForm
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'GasRoutePhysicoChemicalIndicators',
        { type: 'GasRoutePhysicoChemicalIndicator', id: result },
      ],
    }),
  }),
});

export const {
  useGetGasRoutePhysicoChemicalIndicatorQuery,
  useGetGasRoutePhysicoChemicalIndicatorByIdQuery,
  useCreateGasRoutePhysicoChemicalIndicatorMutation,
  useUpdateGasRoutePhysicoChemicalIndicatorByIdMutation,
} = gasRoutePhysicoChemicalIndicatorApi;
