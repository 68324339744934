import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { PressureCategorySettingsReferenceBook } from './types';

export const pressureCategoriesApi = createApi({
  reducerPath: 'pressureCategoriesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/pressure-categories`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => {
    return {
      getPressureCategories: builder.query<
        PressureCategorySettingsReferenceBook[],
        void
      >({
        query: () => ({
          url: ``,
        }),
      }),
    };
  },
});

export const { useGetPressureCategoriesQuery } = pressureCategoriesApi;
