import { Skeleton, SkeletonProps } from '@mui/joy';

export const TextSkeleton = ({
  width = '102px',
  sx,
  ...props
}: SkeletonProps & { width?: string | number }) => {
  return (
    <Skeleton
      variant='text'
      level='title-sm'
      sx={{ width, borderRadius: '20px', ...sx }}
      {...props}
    />
  );
};
