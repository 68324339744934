import { ComponentProps, FC, ReactNode } from 'react';

import { styled } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

import { DateChip } from './DateChip';

const StyledActFormTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const NUMBER_SYMBOL = '№';

type MeteringPointActTitleMessageProps = {
  icon?: ReactNode;
  actNumber?: string;
  startDate?: string;
  level?: ComponentProps<typeof Typography>['level'];
  text?: string;
};

export const MeteringPointActTitleMessage: FC<
  MeteringPointActTitleMessageProps
> = ({
  icon,
  actNumber,
  startDate,
  level = 'body-md',
  text = 'Акт по точці обліку',
}) => (
  <Stack direction='row' gap={1}>
    <StyledActFormTitle level={level}>
      {icon && icon}
      {text}
      {actNumber && (
        <div>
          {NUMBER_SYMBOL}
          {actNumber}
        </div>
      )}
    </StyledActFormTitle>
    {startDate && <DateChip startDate={startDate} />}
  </Stack>
);
