import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { GasPipelineSectionGroupSettingsReferenceBook } from './types';

export const gasPipelineSectionGroupsApi = createApi({
  reducerPath: 'gasPipelineSectionGroupsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/gas-pipeline-section-groups`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  tagTypes: ['GasPipelineSectionGroup'],
  endpoints: builder => ({
    getGasPipelineSectionGroups: builder.query<
      GasPipelineSectionGroupSettingsReferenceBook[],
      void
    >({
      query: () => ({
        url: '/',
      }),
      providesTags: ['GasPipelineSectionGroup'],
    }),
    getGasPipelineSectionGroupById: builder.query<
      GasPipelineSectionGroupSettingsReferenceBook,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/${id}`,
      }),
      providesTags: (_, __, { id }) => [
        { type: 'GasPipelineSectionGroup', id },
      ],
    }),
    createGasPipelineSectionGroup: builder.mutation<
      void,
      {
        body: GasPipelineSectionGroupSettingsReferenceBook;
      }
    >({
      query: ({ body }) => ({
        url: '/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GasPipelineSectionGroup'],
    }),
    updateGasPipelineSectionGroup: builder.mutation<
      void,
      {
        id: string;
        body: GasPipelineSectionGroupSettingsReferenceBook;
      }
    >({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: 'GasPipelineSectionGroup', id },
      ],
    }),
    deleteGasPipelineSectionGroup: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GasPipelineSectionGroup'],
    }),
  }),
});

export const {
  useGetGasPipelineSectionGroupsQuery,
  useGetGasPipelineSectionGroupByIdQuery,
  useCreateGasPipelineSectionGroupMutation,
  useUpdateGasPipelineSectionGroupMutation,
  useDeleteGasPipelineSectionGroupMutation,
} = gasPipelineSectionGroupsApi;
