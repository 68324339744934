import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { ConnectedPower } from '@/features/connectedPowerCalculator/types';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const connectedPowerCalculatorApi = createApi({
  reducerPath: 'connectedPowerCalculatorApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/connected-power-calculator`,
  }),
  endpoints: builder => ({
    getConnectedPower: builder.query<ConnectedPower, string>({
      query: id => ({
        url: `/${id}`,
      }),
    }),
  }),
});

export const { useLazyGetConnectedPowerQuery } = connectedPowerCalculatorApi;
