import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import {
  BridgeAct,
  BridgeActFilters,
  BridgeActRequestPayload,
} from '@/features/bridgeActs/types';
import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

export const bridgeActsApi = createApi({
  reducerPath: 'bridgeActsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/sector-bridge-acts`,
  }),
  tagTypes: ['BridgeActs', 'BridgeAct'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getBridgeActs: builder.query<BridgeAct[], BridgeActFilters | void>({
      query: filters => ({
        url: `/query`,
        method: 'POST',
        data: filters || {},
      }),
      providesTags: ['BridgeActs'],
    }),
    getBridgeActById: builder.query<BridgeAct, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: result => [{ type: 'BridgeAct', id: result.id }],
    }),
    createBridgeAct: builder.mutation<BridgeAct, BridgeActRequestPayload>({
      query: data => ({
        url: ``,
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'BridgeActs',
        { type: 'BridgeAct', id: result.id },
      ],
    }),
    updateBridgeAct: builder.mutation<BridgeAct, BridgeActRequestPayload>({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'BridgeActs',
        { type: 'BridgeAct', id: result.id },
      ],
    }),
  }),
});

export const {
  useGetBridgeActsQuery,
  useGetBridgeActByIdQuery,
  useCreateBridgeActMutation,
  useUpdateBridgeActMutation,
} = bridgeActsApi;
