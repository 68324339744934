import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  EquipmentManufacturer,
  EquipmentManufacturerRequestBody,
} from './types';

export const equipmentManufacturerApi = createApi({
  reducerPath: 'equipmentManufacturerApi',
  baseQuery: axiosBaseQuery({
    baseUrl: Config.VITE_API_URL + '/equipment-manufacturers',
  }),
  tagTypes: ['EquipmentManufacturer', 'EquipmentManufacturers'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getEquipmentManufacturers: builder.query<EquipmentManufacturer[], void>({
      query: () => ({
        url: '/',
      }),
      providesTags: ['EquipmentManufacturer', 'EquipmentManufacturers'],
    }),
    getEquipmentManufacturerById: builder.query<EquipmentManufacturer, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: ['EquipmentManufacturer'],
    }),
    createEquipmentManufacturer: builder.mutation<
      EquipmentManufacturer,
      EquipmentManufacturerRequestBody
    >({
      query: data => ({
        url: '/',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'EquipmentManufacturer',
        { type: 'EquipmentManufacturer', id: result.id },
      ],
    }),
    updateEquipmentManufacturer: builder.mutation<
      EquipmentManufacturer,
      EquipmentManufacturerRequestBody
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'EquipmentManufacturer',
        { type: 'EquipmentManufacturer', id: result.id },
      ],
    }),
  }),
});

export const { useGetEquipmentManufacturersQuery } = equipmentManufacturerApi;
