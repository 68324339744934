import { FC } from 'react';

import { InfoOutlined } from '@mui/icons-material';
import FormHelperText from '@mui/joy/FormHelperText';
import { SxProps } from '@mui/material';

type FormErrorMessage = {
  color?: string;
  children?: React.ReactNode | string | string[];
  sx?: SxProps;
};

export const FormErrorMessage: FC<FormErrorMessage> = ({
  children,
  color = 'danger.500',
  sx = {},
}) => (
  <FormHelperText sx={{ color, ...sx }}>
    <InfoOutlined />
    {children}
  </FormHelperText>
);
