import { createContext, useContext } from 'react';

type FormModalContextType = {
  fieldOptions: { id: string | number; value: string }[];
};

export const FormModalContext = createContext<FormModalContextType | null>(
  null,
);

export const useFormModalContext = () => {
  const context = useContext(FormModalContext);
  if (!context) {
    throw new Error(
      'useFormModalContext must be used within a FormModalContext',
    );
  }
  return context;
};
