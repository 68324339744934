import { Stack } from '@mui/joy';
import { Chip } from '@mui/joy';
import { styled } from '@mui/joy';

export const StyledActFormHeader = styled(Stack)(({ theme }) => ({
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  background: theme.palette.neutral[100],
  borderRadius: theme.spacing(1),
  flexDirection: 'row',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
}));

export const StyledActFormHeaderChip = styled(Chip)<{ isEditMode?: boolean }>(
  ({ theme, isEditMode }) => ({
    color: isEditMode ? theme.palette.neutral[50] : theme.palette.primary[500],
    backgroundColor: isEditMode
      ? theme.palette.success[500]
      : theme.palette.primary[50],
  }),
);
