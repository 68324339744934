import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

import {
  CommercialMeteringPointActFormStep,
  MeteringPointActFormStep,
} from './';

const MeteringPointActFormDevices = React.lazy(
  () => import('@/pages/MeteringPointActForm/views/Devices'),
);

const MeteringPointActFormEquipments = React.lazy(
  () => import('@/pages/MeteringPointActForm/views/Equipments'),
);

const MeteringPointActFormInformation = React.lazy(
  () => import('@/pages/MeteringPointActForm/views/Information'),
);

const MeteringPointActFormSeals = React.lazy(
  () => import('@/pages/MeteringPointActForm/views/Seals'),
);

const MeteringPointActFormPipelineSections = React.lazy(
  () => import('@/pages/MeteringPointActForm/views/PipelineSections'),
);

export const ActFormView: Record<
  | keyof typeof MeteringPointActFormStep
  | keyof typeof CommercialMeteringPointActFormStep,
  string
> = {
  Information: '',
  Devices: 'devices',
  Equipments: 'equipments',
  Seals: 'seals',
  PipelineSection: 'pipelineSection',
};

export const meteringPointActFormRoutes: RouteObject[] = [
  {
    path: ActFormView.Information,
    element: <MeteringPointActFormInformation />,
    index: true,
  },
  {
    path: ActFormView.Devices,
    element: <MeteringPointActFormDevices />,
  },
  {
    path: ActFormView.Equipments,
    element: <MeteringPointActFormEquipments />,
  },
  {
    path: ActFormView.Seals,
    element: <MeteringPointActFormSeals />,
  },
  {
    path: ActFormView.PipelineSection,
    element: <MeteringPointActFormPipelineSections />,
  },
];
