export class StepDataStorage {
  constructor(
    private storage: Storage,
    private storageKey: string,
  ) {}

  private parseData(rowData: string) {
    return JSON.parse(rowData) ?? {};
  }

  private getStoreItem() {
    return this.storage.getItem(this.storageKey);
  }

  getParsedStepsData() {
    return this.parseData(this.getStoreItem());
  }

  setStoreItem(item: any) {
    this.storage.setItem(this.storageKey, JSON.stringify(item));
  }

  removeStoreItem() {
    this.storage.removeItem(this.storageKey);
  }
}
