import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { AdmissionClasses } from './types';

export const admissionClassesApi = createApi({
  reducerPath: 'admissionClassesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/admission-classes`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getAdmissionlasses: builder.query<AdmissionClasses[], void>({
      query: () => ({
        url: '/',
      }),
    }),
  }),
});

export const { useGetAdmissionlassesQuery } = admissionClassesApi;
