import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

import Pages from '@/pages';

const GasThreadMeteringPoints = React.lazy(
  () => import('@/pages/GasThreadDetails/views/MeteringPoints'),
);
const GasThreadMeteringPointsSectors = React.lazy(
  () => import('@/pages/GasThreadDetails/views/Sectors'),
);

const GasThreadInformation = React.lazy(
  () => import('@/pages/GasThreadDetails/views/Information'),
);

const baseGDSThreadsPath = `/${Pages.GAS_DISTRIBUTION_STATIONS}/:stationId/gas-threads/:gasThreadId`;

export const gasDistributionStationGasThreadRoutes: RouteObject[] = [
  {
    path: baseGDSThreadsPath,
    element: <GasThreadInformation />,
    index: true,
  },
  {
    path: `${baseGDSThreadsPath}/${Pages.METERING_POINTS}`,
    element: <GasThreadMeteringPoints />,
  },

  {
    path: `${baseGDSThreadsPath}/${Pages.SECTORS}`,
    element: <GasThreadMeteringPointsSectors />,
  },
];
