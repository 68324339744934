import { FC } from 'react';

import Button from '@mui/joy/Button';

import { DialogActions } from '@components/ui/Dialog/styled';
import { DialogType } from '@components/ui/Dialog/types';

type FormFooterProps = {
  isLoading?: boolean;
  isPreviewMode?: boolean;
  isCreateMode?: boolean;
  isPreviewModeWithoutUpdate?: boolean;
  setFormType: (type: DialogType) => void;
  handleCancel?: () => void;
  handleClose?: () => void;
  submitForm: () => void;
  textSubmit?: string;
};
export const FormFooter: FC<FormFooterProps> = ({
  setFormType,
  handleCancel,
  isLoading,
  submitForm,
  isPreviewMode,
  isCreateMode,
  isPreviewModeWithoutUpdate,
  handleClose,
  textSubmit,
}) => {
  const textButton = textSubmit
    ? textSubmit
    : isCreateMode
      ? 'Створити'
      : 'Зберегти';

  const getDialogActions = () => {
    if (isPreviewMode) {
      return (
        <Button size='sm' onClick={() => setFormType(DialogType.Edit)}>
          Редагувати
        </Button>
      );
    }
    if (isPreviewModeWithoutUpdate) {
      return (
        <Button size='sm' variant='plain' onClick={handleClose}>
          Закрити
        </Button>
      );
    }
    return (
      <>
        {handleCancel && (
          <Button
            size='sm'
            variant='plain'
            onClick={handleCancel}
            disabled={isLoading}
          >
            Скасувати
          </Button>
        )}

        <Button size='sm' onClick={submitForm} disabled={isLoading}>
          {textButton}
        </Button>
      </>
    );
  };
  return <DialogActions>{getDialogActions()}</DialogActions>;
};
