import { useState } from 'react';

import Stack from '@mui/joy/Stack';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';

import { copyTextToClipboard } from '@components/ui/CopyText/utils';

import CopyIcon from '@assets/copy.svg?react';

interface CopyTextProps {
  label?: string;
  value: string | number;
  hasPrefix?: boolean;
}

const CopyText = ({ label = 'ID', value, hasPrefix = true }: CopyTextProps) => {
  const [open, setOpen] = useState(false);
  const handleCopy = async () => {
    await copyTextToClipboard(value);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  const withPrefixStyle = {
    bgcolor: 'neutral.100',
    py: 0.5,
    px: 1,
    gap: 0.5,
  };

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 'sm',
        borderRadius: '8px',
        ...(hasPrefix && withPrefixStyle),
      }}
    >
      <Typography
        component='span'
        level='body-sm'
        sx={{
          color: theme => theme.palette.neutral[700],
        }}
      >
        {hasPrefix && `${label} - `}
        <Typography
          component='b'
          fontWeight='bold'
          sx={{
            color: theme => theme.palette.neutral[800],
          }}
        >
          {value}
        </Typography>
      </Typography>
      <Tooltip
        title='Скопійовано'
        placement='right'
        open={open}
        arrow
        sx={{ cursor: 'pointer' }}
      >
        <Stack>
          <CopyIcon onClick={handleCopy} />
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export default CopyText;
