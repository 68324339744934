import config from '@config';
import axios from 'axios';

import organizationManager from '@/utils/organizationManager';
import tokenManager from '@/utils/tokenManager';

const instance = axios.create({
  baseURL: config.VITE_API_URL,
});

instance.interceptors.request.use(
  async config => {
    const token = tokenManager.getState();
    const organizationId = organizationManager.getState();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (organizationId) {
      config.headers.organizationId = organizationId;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export default instance;
