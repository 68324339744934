import get from 'lodash-es/get';

const PLACEHOLDER = '-';
/**
 * @example:
 * const getInformationAbout = extractInformationByPath<Contract>(contract);
 * ...
 * getInformationAbout('counterparty.fullName')
 * @param information
 */
export const extractInformationByPath =
  <T>(information: T) =>
  <R>(path: string, placeholder: any = PLACEHOLDER): R =>
    get(information, path, placeholder);
