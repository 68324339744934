import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME_REFERENCE_BOOK } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { MeasurementRange } from './types';

export const measurementRangesApi = createApi({
  tagTypes: ['MeasurementRange'],
  reducerPath: 'measurementRangesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/measurement-ranges`,
  }),
  keepUnusedDataFor: CACHE_EXPIRATION_TIME_REFERENCE_BOOK,
  endpoints: builder => ({
    getMeasurementRanges: builder.query<
      MeasurementRange[],
      { meterThroughputId: string | null }
    >({
      query: ({ meterThroughputId = null }) => ({
        url: '/query',
        method: 'POST',
        data: { meterThroughputId },
      }),
    }),
    getMeasurementRangeById: builder.query<MeasurementRange, string>({
      query: id => ({
        url: `/${id}`,
      }),
    }),
  }),
});

export const { useGetMeasurementRangesQuery, useGetMeasurementRangeByIdQuery } =
  measurementRangesApi;
