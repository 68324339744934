import { FC, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ErrorRounded from '@mui/icons-material/ErrorRounded';
import InfoRounded from '@mui/icons-material/InfoRounded';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IconButton, styled } from '@mui/joy';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Snackbar from '@mui/joy/Snackbar';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { DefaultColorPalette } from '@mui/joy/styles/types/colorSystem';

const StyledNotificationHeader = styled(Stack)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export type NotificationType = Exclude<
  DefaultColorPalette,
  'primary' | 'neutral'
>;

const NotificationIcons: Record<NotificationType, typeof ErrorRounded> = {
  danger: WarningAmberIcon,
  success: PlaylistAddCheckCircleRoundedIcon,
  warning: InfoRounded,
};

type NotificationProps = {
  autoHideDuration: number;
  type: NotificationType;
  message: string | string[];
  show?: boolean;
  notificationTitle?: string;
};

const renderMessages = (messages: string[]) => (
  <List sx={{ marginLeft: '25px' }}>
    {messages.map((message, i) => (
      <ListItem key={i}>{message}</ListItem>
    ))}
  </List>
);

export const Notification: FC<NotificationProps> = ({
  autoHideDuration = 10000,
  type,
  message,
  show = true,
  notificationTitle = 'Зверніть увагу!',
}) => {
  const [open, setOpen] = useState(show);
  const Icon = NotificationIcons[type];

  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      variant='soft'
      color={type}
      open={open}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{
        maxWidth: '50%',
        flexDirection: 'column',
        gap: 0,
        bottom: '70px',
      }}
    >
      <StyledNotificationHeader>
        <Icon />
        <Typography level='body-lg' fontWeight='bold'>
          {notificationTitle}
        </Typography>
        <IconButton
          onClick={() => setOpen(false)}
          size='sm'
          variant='soft'
          color={type}
          sx={{
            marginLeft: 'auto',
          }}
        >
          <CloseIcon />
        </IconButton>
      </StyledNotificationHeader>
      {Array.isArray(message) ? renderMessages(message) : message}
    </Snackbar>
  );
};
