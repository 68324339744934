import CircularProgress from '@mui/joy/CircularProgress';
import Stack from '@mui/joy/Stack';
import { alpha } from '@mui/material';

const Loader = () => (
  <Stack
    sx={{
      position: 'fixed',
      width: '100%',
      minHeight: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme => alpha(theme.palette.common.white, 0.5),
      zIndex: 100000,
    }}
  >
    <CircularProgress />
  </Stack>
);

export default Loader;
