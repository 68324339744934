import Config from '@config';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_EXPIRATION_TIME } from '@/features/constants';
import axiosBaseQuery from '@/utils/axiosBaseQuery';

import {
  MeteringPoint,
  MeteringPointFilters,
  MeteringPointRequestBody,
  MeteringPointStatusHistory,
} from './types';

export const meteringPointApi = createApi({
  reducerPath: 'meteringPointApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${Config.VITE_API_URL}/metering-points`,
  }),
  tagTypes: ['MeteringPoint', 'MeteringPoints'],
  keepUnusedDataFor: CACHE_EXPIRATION_TIME,
  endpoints: builder => ({
    getMeteringPoints: builder.query<
      MeteringPoint[],
      MeteringPointFilters | void
    >({
      query: (data = {}) => ({
        url: '/query',
        method: 'POST',
        data,
      }),
      providesTags: ['MeteringPoint', 'MeteringPoints'],
    }),
    getMeteringPointById: builder.query<MeteringPoint, string>({
      query: id => ({
        url: `/${id}`,
      }),
      providesTags: ['MeteringPoint'],
    }),
    createMeteringPoint: builder.mutation<
      MeteringPoint,
      MeteringPointRequestBody
    >({
      query: data => ({
        url: '',
        method: 'POST',
        data,
      }),
      invalidatesTags: result => [
        'MeteringPoint',
        { type: 'MeteringPoint', id: result.id },
      ],
    }),
    updateMeteringPoint: builder.mutation<
      MeteringPoint,
      MeteringPointRequestBody
    >({
      query: data => ({
        url: `/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: result => [
        'MeteringPoint',
        { type: 'MeteringPoint', id: result.id },
      ],
    }),
    getMeteringPointByIdStatusHistory: builder.query<
      MeteringPointStatusHistory[],
      string
    >({
      query: id => ({
        url: `/${id}/status-history`,
      }),
    }),
  }),
});

export const {
  useGetMeteringPointsQuery,
  useLazyGetMeteringPointByIdQuery,
  useGetMeteringPointByIdQuery,
  useCreateMeteringPointMutation,
  useUpdateMeteringPointMutation,
  useGetMeteringPointByIdStatusHistoryQuery,
} = meteringPointApi;
